import React, {ReactElement, useEffect, useState} from "react";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import { Switch as SwitchComponent } from "antd";
import { SwitchProps as AntSwitchProps } from "antd/lib/switch"
import varStatus from "../../components/filters/varStatus";
import { usePermissions } from "../../hooks/usePermissions";

interface SwitchProps extends AntSwitchProps{
    checkedChildren?: ReactElement;
    unCheckedChildren?: ReactElement;
    checked?:boolean;
    varStatus?:string;
    onChange: ( value:boolean ) => Promise<void>|void;
}

export default function Switch( props:SwitchProps ) {

    const
        [ value, setValue ] = useState( props.checked || false ),
        [ switching, setSwitching ] = useState( false ),
        change = async ( value:boolean ) => {
            try {
                setValue( value );
                setSwitching( true )
                await props.onChange( value );
                setSwitching( false );
            }
            catch ( e ) {
                setValue( props.checked || false );
                setSwitching( false )
            }
        }
    const { hasPermissions } = usePermissions();
    const canEditProjectInvoice = hasPermissions([{ customerApplication: "Google", permissionName: "Invoice Project", action: "edit" }]);

    useEffect( () => {
        setValue(props.checked || false );
    }, [props.checked])

    return (
        <SwitchComponent
            loading={switching}
            disabled={switching || props.varStatus ==="INVOICE" && !canEditProjectInvoice}
            checkedChildren={<CheckOutlined/>}
            unCheckedChildren={<CloseOutlined/>}
            checked={value}
            onChange={change}
        />
    )
}
