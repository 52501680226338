import { useEffect, useState } from "react";
import { useMercuryContext } from "../../../user-context";
import Config from "../../../components/glms-front/helpers/config";
import { glms_stage } from "../../../utils/variables";
import SessionStorage from "../../../Utilities/LocalStorage";
import EnumApi from "../../../components/glms-front/helpers/api";
import { fetchComponentTypes } from "../../../Search/ComponentType.filter";
import { fetchLanguageCodes } from "../../../Search/LanguageCode.filter";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import isEmpty from "../../../components/glms-front/helpers/objects";
import {
  failedNotification,
  successfulNotification,
} from "../../../utils/notifications";

const affiliationOptions = [
  { value: "Internal", label: "Internal" },
  { value: "External", label: "External" },
];

export interface AddEditLingusitProps {
  title: string;
  initialValues?: any;
  disabled?: boolean;
  isEdit?: boolean;
  refreshList: () => void;
}

const componentType = new SessionStorage("componentType");
const sourceLanguage = new SessionStorage("sourceLanguage");
const programRole = new SessionStorage("programRole");
const projectRole = new SessionStorage("projectRole");
const product = new SessionStorage("product");
const productArea = new SessionStorage("productAreas");

const AddEditLinguist = ({
  title,
  initialValues,
  disabled,
  isEdit,
  refreshList,
}: AddEditLingusitProps) => {
  const config = new Config(glms_stage);
  const context = useMercuryContext();
  const { accessToken, gpClient } = useMercuryContext();
  const [products, setProducts] = useState<any>([]);
  const [componentTypes, setComponentTypes] = useState<any>([]);
  const [languages, setLanguages] = useState<any>([]);
  const [programRoles, setProgramRoles] = useState<any>([]);
  const [projectRoles, setProjectRoles] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allAreas, setAllAreas] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    componentTypes: false,
    sourceLanguage: false,
    programRoles: false,
    projectRoles: false,
    products: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    const setLoading = (field: string, isLoading: boolean) => {
      setLoadingStates((prevState) => ({
        ...prevState,
        [field]: isLoading,
      }));
    };

    const fetchAndCacheData = async (
      field: string,
      data: any[],
      cache: { data?: any[] },
      fetchFunction: (context: any) => Promise<any[]>,
      setState: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
      try {
        setLoading(field, true);

        if (data.length === 0 && !cache?.data) {
          const result = await fetchFunction(context);
          setState(result);
          cache.data = result;
        } else {
          setState(cache.data || []);
        }
      } catch (error) {
        console.error(`Error fetching ${field}:`, error);
      } finally {
        setLoading(field, false);
      }
    };

    const fetchWithAuth = async (url: string) => {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) throw new Error(`Error fetching ${url}`);
      return response.json();
    };

    const fetchProducts = async () => {
      try {
        setLoading("products", true);

        const cachedProductArea = productArea.data;
        const cachedProducts = product.data;

        const isValidProductArea =
          Array.isArray(cachedProductArea) &&
          cachedProductArea.length > 0 &&
          cachedProductArea.every((area: any) => area && area._id);

        const isValidProducts =
          Array.isArray(cachedProducts) &&
          cachedProducts.length > 0 &&
          cachedProducts.every((product: any) => product?.productArea?._id);

        if (!isValidProductArea || !isValidProducts) {
          const productAreas = await fetchWithAuth(
            `${config.API}/${EnumApi.CoreGpms}/productArea`
          );
          const products = await fetchWithAuth(
            `${config.API}/${EnumApi.CoreGpms}/product`
          );

          const mappedProducts = products.map((product: any) => {
            const productArea = productAreas.find(
              (area: { _id: any }) => area._id === product.productArea
            );

            return {
              ...product,
              productArea: productArea
                ? { _id: productArea._id, title: productArea.title }
                : { _id: "emptyArea", title: "Others" },
            };
          });

          productArea.data = productAreas;
          product.data = mappedProducts;

          setProducts(mappedProducts);
        } else {
          setProducts(cachedProducts);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading("products", false);
      }
    };

    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchAndCacheData(
            "componentTypes",
            componentTypes,
            componentType,
            fetchComponentTypes,
            setComponentTypes
          ),
          fetchAndCacheData(
            "sourceLanguage",
            languages,
            sourceLanguage,
            fetchLanguageCodes,
            setLanguages
          ),
          fetchAndCacheData(
            "programRoles",
            programRoles,
            programRole,
            async () => await fetchWithAuth(`${config.URL}/glms/program/roles`),
            setProgramRoles
          ),
          fetchAndCacheData(
            "projectRoles",
            projectRoles,
            projectRole,
            async () => await fetchWithAuth(`${config.URL}/glms/project/roles`),
            setProjectRoles
          ),
          fetchProducts(),
        ]);
      } catch (error) {
        console.error("Error during initial data fetching:", error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    if (products.length) {
      const areaKeys: any = {};
      for (const product of products) {
        areaKeys[product?.productArea?._id] = product?.productArea?.title;
      }
      const areas = [];
      for (const key in areaKeys) {
        areas.push({ value: key, label: areaKeys[key] });
      }
      setAllAreas(areas);
    }
  }, [products]);

  useEffect(() => {
    if (isModalVisible && !isEmpty(initialValues)) {
      const normalizedValues = { ...initialValues };

      const fieldsToNormalize = ["active", "eligible", "share_notifications"];

      fieldsToNormalize.forEach((field) => {
        if (normalizedValues[field] !== undefined) {
          normalizedValues[field] = Array.isArray(normalizedValues[field])
            ? normalizedValues[field].some(Boolean)
            : Boolean(normalizedValues[field]);
        }
      });

      form.setFieldsValue(normalizedValues);
    }
  }, [initialValues, isModalVisible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const fieldsToTrim = ["personal_email", "sesameId", "sdl_email"];
      const { productAreas, ...filteredValues } = values;

      fieldsToTrim.forEach((field) => {
        if (typeof filteredValues[field] === "string") {
          filteredValues[field] = filteredValues[field].trim();
        }
      });

      if (Array.isArray(filteredValues.program_role)) {
        filteredValues.program_role = filteredValues.program_role.join(", ");
      } else if (typeof filteredValues.program_role !== "string") {
        filteredValues.program_role = String(filteredValues.program_role || "");
      }
      setSubmitting(true);
      let res;
      if (isEdit) {
        res = await gpClient.patch(
          `/google-gp-v1/linguists/${initialValues?.id}`,
          filteredValues
        );
      } else {
        res = await gpClient.post(`/google-gp-v1/linguists`, filteredValues);
      }
      refreshList();
      form.resetFields();
      setIsModalVisible(false);
      successfulNotification(
        `${res?.data?.sesameId} has been ${isEdit ? "updated" : "created"}`,
        "This will be updated in real-time"
      );
    } catch (error: any) {
      if (error?.errorFields) {
        console.error("Validation failed:", error.errorFields);
        return;
      }

      const errorData = error?.response?.data || {};
      const errorMessage =
        errorData?.message ||
        errorData?.errorResponse?.errmsg ||
        errorData?.details?.toString() ||
        "We've found an internal error saving data. Please, try again";

      failedNotification("Error", errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleReset = () => {
    form.resetFields();
  };

  const onProductsChange = (_: any, options: any) => {
    const areas: any = [];
    for (const product of options) {
      if (!areas.includes(product.areaid)) {
        areas.push(product.areaid);
      }
    }
    form.setFieldsValue({
      productAreas: areas,
    });
  };

  const onProductAreasChange = (values: any) => {
    form.setFieldsValue({
      products: products
        .filter((x: any) => values.includes(x.productArea._id))
        .map((product: any) => product.title),
    });
  };

  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const customTagRender = ({ label, closable, onClose }: any) => {
    return (
      <Tag
        color="var(--tertiary-color)"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, marginBottom: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div>
      <Button type="primary" disabled={disabled} onClick={showModal}>
        {title}
      </Button>
      <Modal
        title={title}
        open={isModalVisible}
        className="linguist-modal"
        onCancel={handleCancel}
        width="80%"
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="reset" danger onClick={handleReset}>
            Reset
          </Button>,
          <Button
            disabled={submitting}
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={submitting}
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          name="new-edit-linguists"
          form={form}
          layout="vertical"
          initialValues={{
            name: null,
            personal_email: "",
            sesameId: null,
            sdl_email: "",
            program_role: "",
            affiliation: null,
            eligible: true,
            share_notifications: false,
            active: true,
            project_roles: [],
            language_codes: [],
            sourceLanguage: [],
            components: [],
            products: [],
            productAreas: [],
          }}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="personal_email"
                label="Personal Email"
                rules={[{ type: "email", message: "Invalid email format." }]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="sesameId"
                label="Sesame Account"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input
                  placeholder="Sesame Account"
                  suffix={
                    <Tooltip title="A valid account in Google Translation Enterprise that may have documents shared with it. A Lead will not receive projects unless this is populated">
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="sdl_email" label="RWS Email">
                <Input placeholder="RWS Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="affiliation"
                label="Affiliation"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select options={affiliationOptions} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="components"
                label="Component Types"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Component Types"
                  options={componentTypes}
                  loading={loadingStates.componentTypes}
                  filterOption={(inputValue: any, option: any) =>
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="program_role"
                label="Program Role"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  options={programRoles}
                  loading={loadingStates.projectRoles}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="project_roles"
                label="Project Roles"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Project Roles"
                  loading={loadingStates.projectRoles}
                  options={projectRoles.map((x: any) => ({
                    value: x.value,
                    label: x.value,
                  }))}
                  filterOption={(inputValue: any, option: any) =>
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="productAreas"
                label="Product Areas"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Product Areas"
                  options={allAreas}
                  loading={loadingStates.products}
                  filterOption={(inputValue: any, option: any) =>
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={onProductAreasChange}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="products"
                label="Products"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                  placeholder="Products"
                  loading={loadingStates.products}
                  options={products.map((product: any) => ({
                    value: product?.title,
                    label: product?.title,
                    areaid: product?.productArea?._id,
                    areaname: product?.productArea?.title,
                  }))}
                  filterOption={(inputValue: any, option: any) =>
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={onProductsChange}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="sourceLanguage"
                label="Source Language"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Language"
                  options={languages}
                  loading={loadingStates.sourceLanguage}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="language_codes"
                label="Target Languages"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Languages"
                  options={languages}
                  loading={loadingStates.sourceLanguage}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={3}>
              <Form.Item name="active" label="Active" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Share Eligible">
                <Form.Item name="eligible" valuePropName="checked" noStyle>
                  <Switch />
                </Form.Item>
                <Tooltip title="This Must be checked for a linguist to have projects automatically shared with them.">
                  <InfoCircleOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      marginTop: "8px",
                      marginLeft: "10px",
                    }}
                  />
                </Tooltip>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Receive Sharing Notifications">
                <Form.Item
                  name="share_notifications"
                  valuePropName="checked"
                  noStyle
                >
                  <Switch />
                </Form.Item>
                <Tooltip title="Check this if a Linguist will receive notifications via email when a project is shared with them.">
                  <InfoCircleOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      marginTop: "8px",
                      marginLeft: "10px",
                    }}
                  />
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default AddEditLinguist;
