import React, { useState, useEffect, useCallback } from "react";
import { HELIX_BASE_URL } from "../../../utils/variables";
import axios from "axios";
import JSONPretty from "react-json-pretty";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { defaultHeaders } from "../../../api/APIUtils";
import { useMercuryContext } from "../../../user-context";
import TimezoneConverter from "../../../timezone-converter";

import {
  Table,
  Spin,
  Modal,
  Typography,
  Tooltip,
  Popconfirm,
  Button,
} from "antd";
import {
  CopyOutlined,
  WarningOutlined,
  PullRequestOutlined,
} from "@ant-design/icons";

import {
  failedNotification,
  infoNotification,
  successfulNotification,
} from "../../../utils/notifications";

import { usePermissions } from "../../../hooks/usePermissions";

const HelixIntegrationTab = ({ projectId, varStatus }) => {
  
  const { Text } = Typography;

  const { accessToken, isAdmin, gpSocket } = useMercuryContext();

  const [list, setList] = useState([]),
    [total, setTotal] = useState(0),
    [modalInfo, setModalInfo] = useState({
      isVisible: false,
      activeVersion: "",
    });

  const [jsonData, setJsonData] = useState({}),
    [pageSize, setPageSize] = useState(20),
    [isLoading, setIsLoading] = useState(false),
    [isLoadingJson, setIsLoadingJson] = useState(false),
    [isUpdating, setIsUpdating] = useState(false),
    [isCheckingJobCreation, setIsCheckingJobCreation] = useState(false);

  const { hasPermissions } = usePermissions();

  const fetchList = useCallback(
    async (token) => {
      // TODO: create base url variable for helix
      const endpoint = `${HELIX_BASE_URL}/his/projects/${projectId}/updates`;
      try {
        setIsLoading(true);
        await axios
          .get(endpoint, {
            headers: defaultHeaders(token),
          })
          .then((result) => {
            const fetchedList = [];

            result.data.forEach((item) =>
              fetchedList.push({
                key: item.updateId.toString(),
                version: item.updateId,
                dateSent: item.updateDate,
              })
            );

            setList(fetchedList);
            setTotal(result.data.length);
          });
      } catch (error) {
        failedNotification(
          "Error",
          "Failed to retrieve Helix Integration data."
        );
        console.error(error);
      }
      setIsLoading(false);
    },
    [projectId]
  );

  useEffect(() => {
    accessToken && fetchList(accessToken);
  }, [accessToken, fetchList, projectId]);

  const getJson = async (token, updateId) => {
    // TODO: create base url variable for helix
    const endpoint = `${HELIX_BASE_URL}/his/projects/${projectId}/updates/${updateId}`;
    try {
      setIsLoadingJson(true);
      await axios
        .get(endpoint, {
          headers: defaultHeaders(token),
        })
        .then((result) => {
          setJsonData(result.data);
        });
    } catch (error) {
      failedNotification(
        "Error",
        "Failed to retrieve the JSON that was sent to Helix."
      );
      console.error(error);
    }
    setIsLoadingJson(false);
  };

  const updateHelix = async (token, projectId) => {
    setIsUpdating(true);
    // TODO: create base url variable for helix
    const socket = gpSocket.project;

    socket.emit( "sendToHelix", {
      shortId: `${projectId}`,
      force: true,
    }, ( res ) => {
      console.log( "res", res )
        if ( !res.error ) {
          successfulNotification(
              "Success. A new version was created.",
              "Data was sent to Helix."
          );
          fetchList(accessToken);
        }
        else {
          failedNotification("Error", "Failed to send data to Helix.");
        }
        setIsUpdating(false);
    } )

  };

  // Table columns
  const tableColumns = [
    {
      title: "Version",
      dataIndex: "version",
      width: 150,
      sorter: (a, b) => {
        a = a.version === null ? "" : a.version;
        b = b.version === null ? "" : b.version;
        return a - b;
      },
      defaultSortOrder: "descend",
      render: (text, record) => (
        <a
          href={`modal/version/${text}`}
          onClick={(e) => handleClickVersion(e, record.version)}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Date Sent",
      dataIndex: "dateSent",
      sorter: (a, b) => {
        a = a.dateSent === null ? "" : a.dateSent;
        b = b.dateSent === null ? "" : b.dateSent;
        return a.localeCompare(b);
      },
      render: (text) => <TimezoneConverter date={Number(text)} />,
    },
    {
      title: `Total: ${total}`,
      width: 70,
    },
  ];

  const handleClickVersion = (e, updateId) => {
    e.preventDefault();
    getJson(accessToken, updateId);
    setModalInfo({ isVisible: true, activeVersion: updateId.toString() });
  };

  const handleClickSendToHelix = () => {
    updateHelix(accessToken, projectId);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { pageSize } = pagination;
    setPageSize(pageSize);
  };

  const handleCloseModal = () => {
    setModalInfo({ isVisible: false, activeVersion: "" });
    setJsonData({});
  };

  const handleClickCheckJobCreation = async () => {
    setIsCheckingJobCreation(true);
    const endpoint = `${HELIX_BASE_URL}/his/project/checkJobCreationStatus`;
    try {
      await axios
        .post(
          endpoint,
          { projectId: projectId },
          {
            headers: defaultHeaders(accessToken),
          }
        )
        .then((result) => {
          const { data } = result,
            { failed, helixErrors, helixJobId, pending } = data;

          let msg = "";

          // pending = true -> job has not been created yet. will be shortly.
          // pending & failed = false -> this project has not been sent to helix
          // pending = false & failed = true -> this project failed to be created in helix - display helixErrors
          // helixJobId is set -> (1st check) this is the job id
          if (helixJobId) {
            msg = `The project was created in helix. Helix job ID: ${data.helixJobId}`;
          } else if (pending) {
            msg = "Job has not been created yet. Will be done shortly.";
          } else if (!pending & !failed) {
            msg = "This project has not been sent to Helix.";
          } else if (!pending & failed) {
            msg = `This project failed to be created in Helix. Errors: ${helixErrors.length > 0 ? helixErrors.map(
              (e, index) => {
                let res = "No errors.";

                if (helixErrors.length > 0) {
                  res = `${index + 1}: ${e} `;
                }

                return res;
              }
            ) : 'No errors were found.'}`;
          }

          infoNotification(`Job creation status`, msg);
        });
    } catch (error) {
      failedNotification("Error", "Failed to check job creation status.");
      console.error(error);
    }

    setIsCheckingJobCreation(false);
  };

  const confirmationMessage = (
    <div>
      <span style={{ fontWeight: "bold" }}>Send to Helix</span>
      <hr style={{ margin: "5px 0" }} />
      <span>Are you sure you want to force sending this project to Helix?</span>
      <br />
      <span>You should only do this if you are aware of the consequences.</span>
      <br />
      <span>
        Projects will normally be automatically sent to Helix, so this should
      </span>
      <br />
      <span>
        only be done if there is a specific issue that you are aware of.
      </span>
    </div>
  );

  // console.log("userLevelPermissions", userLevelPermissions);

  // const helixEditPermission = userLevelPermissions.find(
  //   (p) => p.permissionId === "63774e819dc9b860243143b0"
  // )?.edit; // TODO: replace id with permission name

  const canEditProjectInvoice = hasPermissions([{ customerApplication: "Google", permissionName: "Invoice Project", action: "edit" }]);

  return isLoading ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Spin size="small" style={{ margin: "0 10px" }} />
      Loading list...
    </div>
  ) : (
    <>
      <div className="helix-actions" style={{ padding: "0 15px" }}>
        {isAdmin && (
          <Popconfirm
            onConfirm={handleClickSendToHelix}
            okText="Send"
            cancelText="Cancel"
            okType="danger"
            icon={<WarningOutlined style={{ color: "var(--red)" }} />}
            title={confirmationMessage}
            cancelButtonProps={{ type: "ghost" }}
            okButtonProps={{ type: "primary" }}
          >
            <Button icon={<PullRequestOutlined />} loading={isUpdating} disabled={varStatus ==="INVOICE"}>
              Send to Helix
            </Button>
          </Popconfirm>
        )}
        {
          // TODO: isAdmin check with permission name - once MER-1814 is done
          // helixEditPermission && (
            isAdmin && (
            <Button
              onClick={() => handleClickCheckJobCreation()}
              type="primary"
              style={{ marginLeft: "8px" }}
              loading={isCheckingJobCreation}
              disabled={varStatus ==="INVOICE" && !canEditProjectInvoice}
            >
              Check Job Creation
            </Button>
          )
        }
      </div>
      <Table
        columns={tableColumns}
        dataSource={list}
        className={"helix-integration-table"}
        size="small"
        style={{ margin: "10px 15px 20px 15px" }}
        pagination={{
          position: ["bottomCenter"],
          pageSize,
        }}
        onChange={handleTableChange}
      />
      <Modal
        title={`Version ${modalInfo.activeVersion}`}
        open={modalInfo.isVisible}
        onCancel={handleCloseModal}
        width={650}
        style={{ padding: "12px 24px" }}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Close"
        cancelButtonProps={{ type: "primary" }}
      >
        {isLoadingJson ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Spin size="small" style={{ margin: "0 10px" }} />
            Loading JSON...
          </div>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text>
                Shown below is the JSON that was sent to Helix for this version
                of the project:
              </Text>
              <Tooltip
                title="Copy JSON to Clipboard"
                color={"var(--tertiary-color)"}
                placement={"bottom"}
              >
                <CopyToClipboard text={JSON.stringify(jsonData, undefined, 2)}>
                  <CopyOutlined
                    style={{
                      color: "var(--tertiary-color)",
                      padding: "5px 0",
                    }}
                    onClick={() =>
                      successfulNotification(
                        "Copied successfully",
                        "The JSON was copied to your clipboard."
                      )
                    }
                  />
                </CopyToClipboard>
              </Tooltip>
            </div>
            <Text mark>
              <JSONPretty
                id="json-pretty"
                data={jsonData}
                style={{ overflowY: "scroll", maxHeight: "60vh" }}
              />
            </Text>
          </>
        )}
      </Modal>
    </>
  );
};

export default HelixIntegrationTab;
