import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Collapse, Tabs, TabsProps } from "antd";
import { Error } from "../../../stories/Errors/Error";
import LoadingStar from "../../../ui/LoadingStar";
import PlpGeneralInformation from "./PlpGeneralInfomation";
import BackTopButton from "../../../ui/BackTopButton";
import { useMercuryContext } from "../../../user-context";
import {
  CalendarOutlined,
  CreditCardOutlined,
  HistoryOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import SharingTab from "../../Project/ProjectDetails/SharingTab";
import PlpInvoicing from "./PlpInvoicing";
import StatusHistory from "../../StatusHistory";
import { PlpExtensions } from "./PlpExtensions";
import PlpInformation from "./PlpInformation";
import Breadcrumbs from "../../../stories/BreadCrumb/Breadcrumbs";

interface State {
  error?: any;
  loading: boolean;
  details?: any;
}

class PlpDetails extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
      loading: false,
      details: undefined,
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch(updating?: boolean, setUpdating?: any) {
    if (!updating) {
      this.setState({ loading: true });
    }
    try {
      const { data } = await this.props.context.gpClient.get(
        `/google-gp-v1/plp/${this.props.plpId}`
      );
      this.setState({ details: data, loading: false });
      if (updating) {
        setUpdating(false);
      }
    } catch (e: any) {
      this.setState({ error: e, loading: false });
    }
  }

  reload = async () => {};

  plpDetails() {
    const items = [
      {
        key: "1",
        label: "General Information",
        children: (
          <PlpGeneralInformation
            fetch={(setUpdating: any) => this.fetch(true, setUpdating)}
            details={this.state.details}
          />
        ),
        className: "general-information",
      },
    ];

    return (
      <Collapse
        defaultActiveKey={["1"]}
        items={items}
        style={{ marginBottom: "10px" }}
      />
    );
  }

  tabs() {

    const { userPermissions } = this.props.context.userDetails;

    const { details } = this.state,
      { shortId, customVendorPLPMetadata } = details;

    const tabItems: TabsProps["items"] = [
      {
        key: "1",
        label: (
          <span>
            <CreditCardOutlined /> PLP Details
          </span>
        ),
        children: (
          <PlpInformation plpId={details?._id} context={this.props.context} />
        ),
      },
      {
        key: "2",
        label: (
          <span>
            <ShareAltOutlined /> PLP Sharing
          </span>
        ),
        children: (
          <SharingTab
            targetLanguageCode={details?.targetLanguageCode}
            shortId={shortId}
            varStatus={customVendorPLPMetadata?.varStatus}
            userPermissions={userPermissions}
          />
        ),
      },
      {
        key: "3",
        label: (
          <span>
            <CalendarOutlined /> Extensions
          </span>
        ),
        children: (
          <Collapse
            defaultActiveKey={["1"]}
            style={{ margin: "20px 20px" }}
            items={[
              {
                key: "1",
                label: "Extensions",
                children: (
                  <PlpExtensions
                    projectId={shortId}
                    filters={{
                      targetLanguageCode: [details?.targetLanguageCode],
                    }}
                  />
                ),
                className: "extensions",
              },
            ]}
          />
        ),
      },
      {
        key: "4",
        label: (
          <span>
            <CreditCardOutlined /> Invoicing
          </span>
        ),
        children: (
          <Collapse
            defaultActiveKey={["1"]}
            style={{ margin: "0 15px 20px 15px" }}
            items={[
              {
                key: "1",
                label: "Details",
                children: (
                  <PlpInvoicing
                    invoiceData={details?.invoiceData}
                    workLog={details?.workLog}
                    totalWeightedWordCount={details?.totalWeightedWordCount}
                  />
                ),
              },
            ]}
          />
        ),
      },
      {
        key: "5",
        label: (
          <span>
            <HistoryOutlined /> Status History
          </span>
        ),
        children: (
          <StatusHistory
            statusHistory={details?.customVendorPLPMetadata?.statusHistory}
          />
        ),
      },
    ];

    return <Tabs defaultActiveKey="1" size="small" items={tabItems} />;
  }

  render() {
    const { details } = this.state;
    const { shortId, targetLanguageCode } = details || {};
    const { plpId } = this.props;
    if (this.state.error) {
      return (
        <Error
          reload={this.reload}
          loading={this.state.loading}
          error={JSON.stringify(this.state.error)}
        />
      );
    }

    if (this.state.loading || !this.state.details) {
      return (
        <LoadingStar
          logo={true}
          title={
            <div>
              Retrieving details for project: <br /> {plpId}{" "}
            </div>
          }
        />
      );
    }

    return (
      <>
        <Helmet>
          <title>
            {shortId} - {targetLanguageCode} - Mercury © RWS
          </title>
        </Helmet>
        <div className="project-details-content">
          <Breadcrumbs
            separator="-"
            items={[
              {
                key: "projectId",
                label: details?.shortId,
                href: `/project/${shortId}`,
                tooltip: "Project ID",
                color: "var(--primary-color)",
              },
              {
                key: "projectTitle",
                label: details?.projectTitle,
                level: 4,
              },
              {
                key: "plpId",
                label: details?.targetLanguageCode,
                color: "var(--primary-color)",
              },
            ]}
          />
          <div
            style={{ padding: "5px 0 10px 0" }}
            className="project-details-body"
          >
            {this.plpDetails()}
            {this.tabs()}
            <BackTopButton />
          </div>
        </div>
      </>
    );
  }
}

const PlpDetailsPage = function (props: any) {
  const context = useMercuryContext(),
    { plpId } = useParams();

  return (
    <PlpDetails context={context} location={props.location} plpId={plpId} />
  );
};

export default PlpDetailsPage;
