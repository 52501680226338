import React, { useState } from "react";
import {
  Button,
  Form,
  Modal,
  Tooltip,
  Dropdown,
  Select,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  successfulNotification,
  failedNotification,
} from "../utils/notifications";
import { useMercuryContext } from "../user-context";

import type { SizeType } from 'antd/es/config-provider/SizeContext';
import {
  FiniteDropDownWithAsyncLoader,
  FiniteDropDownWithAsyncLoaderItem
} from "../stories/FiniteDropDown/FiniteDropDownWithAsyncLoader.abstract";
import { usePermissions } from "../hooks/usePermissions";

import "./OwnerAssign.scss"

type Options = "assign"|"take"|"release";

type DropDownPlacementOptions = "bottomLeft" | "topLeft" | "topCenter" | "topRight" | "bottom" | "bottomRight" | undefined;

export interface OwnerAssignProps {
  options?:Options[];
  block?:boolean;
  text?: string;
  buttonSize?: SizeType; // small, middle (default), large
  dropdownPlacement?: DropDownPlacementOptions; // bottom bottomLeft(default) bottomRight top topLeft topRight
  selectedRows: any;
  users?: any[];
  reload: () => Promise<void>;
  bust: () => void;
  varStatus?: string;
}


class UserDetails extends FiniteDropDownWithAsyncLoader {
  async fetch(signal: any): Promise<FiniteDropDownWithAsyncLoaderItem[]> {

    const { data } = await this.props.context.umsClient.get("/core-ums-v2/users/list");

    return data.filter((item: any) => item.isActive).map( ( item: any ) => {
      return {
        value: item._id,
        label: item.name
      }
    });
  }

}


const OwnerAssign = ({options, block, text, buttonSize = 'middle', dropdownPlacement = 'bottomLeft', selectedRows, reload, bust, varStatus}:OwnerAssignProps) => {
  const
      context = useMercuryContext(),
      {
        gpClient: Client,
        gpSocket,
        userDetails
      } = context;

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [user, setUser] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>( false );
  const [form] = Form.useForm();

  const projectIds: any = [];
  selectedRows.map((item: any) => projectIds.push(item.key));

  const projectShortIds = selectedRows?.map((p: any) => p.shortId);
  const firstProjectIds = projectShortIds.slice(0, 10);
  const moreProjectIds = projectShortIds?.length - firstProjectIds.length;
  const { hasPermissions } = usePermissions();
  const canEditProjectInvoice = hasPermissions([{ customerApplication: "Google", permissionName: "Invoice Project", action: "edit" }]);
  const allowedSubmitCriteria = (element: any) => element.varStatus !== "INVOICE";
  const allowSubmit = selectedRows.length > 0 && selectedRows.filter( ( e: any ) => e.deleted !== true).filter(allowedSubmitCriteria).length;

  const ownerAssign = async (values: any, submitted: boolean) => {
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setIsOpen(false);
        return;
      }

      setLoading( true );

      gpSocket.project.emit( "assign", {
        projectIds: projectIds,
        userId: values.userId,
      }, () => {
        setLoading( false );
        successfulNotification(
            `Project ID(s): ${firstProjectIds.length > 1 ? firstProjectIds.join(
                ", "
            ) : projectShortIds } ${projectShortIds.length > 10 ?  '... ' + moreProjectIds + ' more' : ''} have been assigned successfully`,
            "The data has been reloaded"
        )
      } )

      // return Client.patch(`google-gp-v1/project/ownership/assign`, )
      // .then(() => {
      //   if ( bust ) bust();
      //   reload()
      //       .then( () => successfulNotification(
      //         `Project ID(s): ${firstProjectIds.length > 1 ? firstProjectIds.join(
      //             ", "
      //           ) : projectShortIds } ${projectShortIds.length > 10 ?  '... ' + moreProjectIds + ' more' : ''} have been assigned successfully`,
      //           "The data has been reloaded"
      //         )
      //     )
      //
      // })
      // .catch((error: any) => {
      //   if (error.response) {
      //     failedNotification(
      //       error.response.data.error,
      //       error.response.data.detail
      //     );
      //   } else {
      //     failedNotification("Failed to take ownership");
      //   }
      // })
      // .finally( () => {
      //   setLoading( false );
      // })
    } catch (error) {
      failedNotification("Failed to take ownership");
    }
  };

  const ownerRelease = async () => {
    try {
      setLoading( true );

      gpSocket.project.emit( "release", {
        projectIds: projectIds
      }, () => {
        setLoading( false );
        successfulNotification(
          `${
              userDetails?.email
            } has been released from ownership of Project ID(s): ${firstProjectIds.length > 1 ? firstProjectIds.join(
              ", "
            ) : projectShortIds } ${projectShortIds.length > 10 ?  '... ' + moreProjectIds + ' more' : ''}`,
            "The data has been reloaded"
          )

      } )

      // if ( bust ) bust();
      // return Client.patch(`google-gp-v1/project/ownership/release`, {
      //   projectIds: projectIds
      // })
      //   .then(() => {
      //     reload().then(() =>
      //     successfulNotification(
      //       `${
      //           userDetails?.email
      //         } has been released from ownership of Project ID(s): ${firstProjectIds.length > 1 ? firstProjectIds.join(
      //           ", "
      //         ) : projectShortIds } ${projectShortIds.length > 10 ?  '... ' + moreProjectIds + ' more' : ''}`,
      //         "The data has been reloaded"
      //       )
      //     );
      //   })
      //   .catch((error: any) => {
      //     if (error.response) {
      //       failedNotification(
      //         error.response.data.error,
      //         error.response.data.detail
      //       );
      //     } else {
      //       failedNotification("Failed to release ownership");
      //     }
      //   })
      //   .finally( () => {
      //     setLoading( false );
      //   })
    } catch (error) {
      failedNotification("Failed to release ownership");
    }
  };

  const determineArrowPosition = (placement: string) => {
    switch (placement) {
      case "topLeft":
        return 'rotate(180deg)'
      case "topCenter":
        return 'rotate(180deg)'
      case "topRight":
        return 'rotate(180deg)'
      default:
        return ''
    }
  }

  function handleChangeUser(value: string) {
    setUser(value);
  }

  const items = [];

  if  (!options || options.indexOf("assign") !== -1) {
    items.push({
      label: <button onClick={() => setIsOpen(true)}>Assign Owner</button>,
      key: "1",
    })
  }

  if (!options || options.indexOf("take") !== -1) {
    items.push({
      label: <button onClick={async () => { await ownerAssign({userId: userDetails._id}, true)}}>Take Ownership</button>,
      key: "2",
    })
  }

  if (!options || options.indexOf("release") !== -1) {
    items.push({
      label: <button onClick={async () => { await ownerRelease() }}>Release Ownership</button>,
      key: "3",
    })
  }

  return (
    <>
      <Dropdown
        overlayClassName='owner-assign-dropdown'
        placement={dropdownPlacement}
        trigger={['click']}
        menu={{
          items
        }}
        disabled={ selectedRows.length === 0 }
      >
        <Button loading={loading} className="action-btn edit-btn" type="primary" block={block} size={buttonSize} >
          { text || "Ownership" }
          <DownOutlined style={{transform: determineArrowPosition(dropdownPlacement)}}/>
        </Button>
      </Dropdown>
      <Modal
        open={isOpen}
        title="Assign Owner"
        okText="Assign"
        cancelText="Cancel"
        onCancel={async () => {
          await ownerAssign("", false);
        }}
        maskClosable={false}
        onOk={async () => {
          try {
            if (await form.validateFields()) {
              let values = form.getFieldsValue();
              setConfirmLoading(true);
              await ownerAssign(values, true);
            }
            setConfirmLoading(false);
            setIsOpen(false);
            form.resetFields();
          } catch (error) {
            console.error("Validate Failed:", error);
          }
        }}
        confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          layout="vertical"
          name="ownerAssignForm"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="userId"
            label="Owner"
            rules={[{ required: true, message: "Please select an Owner" }]}
          >
            <UserDetails
                id={"user"}
                context={context}
                name={"owner"}
                value={user as any}
                onChange={setUser}
                showSearch
                allowClear
                placeholder="Select Owner"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OwnerAssign;
