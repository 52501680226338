import { useState, useEffect, FunctionComponent } from "react";
import {
  Button,
  Modal,
  Steps,
  Form,
  Select,
  Typography,
  Row,
  Col,
  Progress,
  Switch,
  Input,
  AutoComplete, Alert
} from "antd";
import { ArrowDownOutlined, WarningOutlined, BuildOutlined } from "@ant-design/icons";
import "./captionhubsendform.scss";
import Logo from "../Loaders/Logo";
import varStatus from "../../components/filters/varStatus";
import { usePermissions } from "../../hooks/usePermissions";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Step } = Steps;

// Define interfaces used by the component
interface CaptionHubWizardProps {
  projectDoc: any,
  baseURL: string | undefined,
  // context: UserContext,
  context: any,
  onWizardClose?: (dataChanged: boolean) => void,
  varStatus:  string | undefined,
}

interface ProjectListItem {
  shortId: any,
  title: any,
  description: any,
}

interface DocumentListItem {
  id: string,
  title: string,
  targetLanguageCode: string,
  driveLink: string,
  documents: any,
  meta: string;
}

interface CaptionHubDocument {
  id?: string;
  // title: string;
}

interface CaptionHubDetail {
  driveLink: string;
  documents: CaptionHubDocument[];
  targetLanguageCode: string;
  meta: any;
}

interface CaptionHubProject {
  name: string;
  automation: string;
  deliveryFolder: string;
  description:string;
  notes?:string,
  videoEncoding:boolean;
  details: CaptionHubDetail[];
  shortId: string;
}

export const CaptionHubSendForm: FunctionComponent<CaptionHubWizardProps> = ({
  projectDoc,
  baseURL,
  context,
  onWizardClose,
  varStatus,
}) => {

  const NUM_WIZARD_STEPS: number = 3;
  const SUCCESS_COLOR: string = "var(--primary-color)";
  const ERROR_COLOR: string = "var(--red)";

  const [step1Form] = Form.useForm();
  const [step2Form] = Form.useForm();

  const { hasPermissions } = usePermissions();
  const canEditProjectInvoice = hasPermissions([{ customerApplication: "Google", permissionName: "Invoice Project", action: "edit" }]);

  function extractGoogleDriveURLs(text: any, folderOptions = true) {

    let result: any;

    const drive = /https:\/\/drive\.google\.com\/(?!.*\/folders)[^\s]+/g;
    const folder = /https:\/\/drive\.google\.com\/.*\/folders[^\s]+/g;

    const driveMatches = text?.match(drive);
    const folderMatches = text?.match(folder);

    // Create an array of objects, each containing the match
    if (folderOptions) {
      result = folderMatches?.map((f: any) => ({folder: f}));
    }

    if (!folderOptions) {
      result = driveMatches?.map((d: any) => ({drive: d}));
    }

    return result || [];
  }

  const
      allDescriptions = [ projectDoc.description, ...projectDoc.documents.map( ( doc:any ) => doc.description ) ].filter( ( description:string ) => description !== null ).flat(),
      allFolderLinks = new Set( Array.from( new Set( allDescriptions ) ).map( ( des:string ) => extractGoogleDriveURLs( des , true)).flat());

  const folderOptions: any = Array.from(allFolderLinks).map((g: any) => ({ value: g.folder }));

  const
      driveOptions: any = Array.from( new Set( allDescriptions ) ).map( des => extractGoogleDriveURLs(des, false)?.map((g: any) => ({ value: g.drive }))).flat()

  const [loading, setLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [project, setProject] = useState<ProjectListItem | null>(null);
  const [documentList, setDocumentList] = useState<CaptionHubDetail[]>([]);
  const [updateList, setUpdateList] = useState<CaptionHubDetail[]>([]);
  const [progressPercent, setProgressPercent] = useState(0);
  const [progressColor, setProgressColor] = useState(SUCCESS_COLOR);
  const [notification, setNotification] = useState<any>({ message: '', loadError: false, fail: false });
  const [automations, setAutomations] = useState<any[]>([]);
  const [destinationFolder, setDestinationFolder] = useState<any>( folderOptions[0]?.value );
  const [destinationValid, setDestinationValid] = useState( false );
  const [destinationValidating, setDestinationValidating] = useState( false );


  const socket = context.chSocket.captionHub;

  useEffect(() => {
    step2Form.setFieldsValue({
      documentList: documentList,
    });
  }, [step2Form, open]);

  // Data retrieval and preparation methods

  function filterDocuments(documents: any) {
    const uniqueDocuments = [];
    const titlesSeen = new Set(); // To keep track of seen titles
    for (const doc of documents) {
      if (!titlesSeen.has(doc.title)) {
        uniqueDocuments.push(doc);
        titlesSeen.add(doc.title);
      }
    }
    return uniqueDocuments;
  }

  const retrieveProject = async () => {

    setProject(projectDoc)

    const filteredDocs = filterDocuments(projectDoc.documents);

    setDocumentList(filteredDocs)
  };

  const getAutomations = async () => {
    try {
      return await new Promise((accept, reject) => {
        context.client.get(
          `${baseURL}/core-ch-v1/captionHub/automations`,
        )
          .then((response: any) => {

            let automationOptions = response.data?.map((a: any) => (
              {
                id: a.id,
                label: a.name,
                value: a.id,
              }
            ));

            setAutomations(automationOptions);
            return accept(null);
          })
          .catch((error: any) => {
            console.error(error.response.data)
            return reject(error);
          })
      });
    } catch (e: any) {
      console.error(e.response.data)
    }
  };

  // Gets a list of the documents for the given Project
  const prepareDocumentList = (values: any) => {
    const projectDetails: any =
    {
      captionHubAutomation: values.captionHubAutomation,
      captionHubDeliveryFolder: Array.isArray(values?.captionHubDeliveryFolder) ? values?.captionHubDeliveryFolder?.find((f: any) => f) : values?.captionHubDeliveryFolder,
      captionHubDescription: values.captionHubDescription,
      captionHubNotes: values.captionHubNotes,
      captionHubVideoEncoding: values.captionHubVideoEncoding
    }

    const finalDocumentList = documentList.map((document: any): DocumentListItem => {
      return {
        id: document._id,
        title: document.title,
        targetLanguageCode: document.targetLanguageCode,
        driveLink: document.driveLink,
        documents: [],
        meta: ""
      }
    })

    setDocumentList(finalDocumentList);

    step1Form.setFieldsValue({
      projectDetails: projectDetails
    })

    step2Form.setFieldsValue({
      documentList: finalDocumentList
    })
  }

  //Gets a list of the documents to actually update based on what the user has selcted in the UI
  const prepareUpdateList = async (documentList: CaptionHubDetail[]) => {

    setConfirmLoading(true);

    let documentsByDriveLink: any = {};

    // Prepare list of documents per drive link
    documentList?.forEach(item => {
      const { driveLink, id, meta }: any = item;
      // const { driveLink, title }: any = item;

      // If the driveLink is not already in the object, create an empty array for it
      if (!documentsByDriveLink.hasOwnProperty(driveLink)) {
        documentsByDriveLink[driveLink] = [];
      }
      // Push id and title into the documents
      documentsByDriveLink[driveLink]?.push(id);
      // documentsByDriveLink[driveLink]?.push({ title: title });
    });

    // Create an array of objects, each containing the driveLink and its titles
    const result: any = Object.entries(documentsByDriveLink).map(([driveLink, documents]) => ({
      driveLink,
      documents
    }));

    // validate drive links
    try {


      setLoading( true )
      const files = new Set(result.map((doc: any) => {
        return doc.driveLink
      }))


        context.client
          .post(
            `${baseURL}/core-ch-v1/captionHub/ValidateFile`, Array.from(files))
          .then((response: any) => {
            setCurrent(current + 1);
            const
                { data } = response,
                mappped = new Map();

            result.forEach( ( doc:any ) => {
              mappped.set( doc.driveLink, {
                ...doc,
                meta: data[doc.driveLink ].meta
              })
            })

            setUpdateList( Array.from( mappped.values() ) );

          })
          .catch((error: any) => {
            setCurrent(current);
            setNotification({
              message: `We were unable to validate the file(s). Please close the wizard and try again or contact support.`,
              loadError: false,
              fail: true
            })
          })
          .finally(() => {
            setConfirmLoading(false);
          });


    } catch (error) {
      setNotification({
        message: `We were unable to validate the file(s). Please close the wizard and try again or contact support.`,
        loadError: false,
        fail: true
      })
    }
    finally {
      setLoading( false )
    }
  }

  // Sends the update to the server

  const createProject = async () => {
    try {
      if (await step2Form.validateFields()) {
        setProgressPercent(100)
        await sendToCaptionHub();
      }
    } catch (error) {
      console.error("Validate Failed:", error);
    }
  };

  const sendToCaptionHub = async () => {

    setConfirmLoading(true);

    try {

      const step1Values: any = step1Form.getFieldValue("projectDetails");

      let captionHubData: CaptionHubProject = {
        name: project?.title,
        automation: step1Values.captionHubAutomation,
        deliveryFolder: step1Values.captionHubDeliveryFolder,
        description: step1Values.captionHubDescription,
        notes: step1Values.captionHubNotes,
        videoEncoding: step1Values.captionHubVideoEncoding,
        details: [...updateList] as CaptionHubDetail[],
        shortId: project?.shortId
      }

      return await new Promise((accept, reject) => {
        context.client.post(
          `${baseURL}/core-ch-v1/captionHub`,
          [captionHubData]
        )
          .then((response: any) => {

            const driveLinks = response?.data[0]?.details.map((d: any) => d.driveLink).length;
            setNotification({
              message: `Sent Successfully. ${driveLinks} project(s) with project ID: ${response?.data[0]?.shortId} have been sent to CaptionHub.`,
              loadError: false,
              fail: false
            })
            setProgressColor(SUCCESS_COLOR);
            setTimeout(() => setOpen(false), 8000);
            return accept(null);
          })
          .catch((error: any) => {
            const errorType = error?.response?.data?.type;
            setNotification({
              message: `${
                errorType === "Duplicate_indexed_field"
                  ? `Project ID: ${project?.shortId} already exists in CaptionHub and cannot be sent again. Please refer to CaptionHub.`
                  : `We were unable to send Project with ID: ${project?.shortId} to CaptionHub. Please close the wizard and try again or contact support.`
              }`,
              loadError: false,
              fail: true,
            });
            setProgressColor(ERROR_COLOR);
            return reject(error);
          })
          .finally(() => {
            setConfirmLoading(false);
            step1Form.resetFields();
            step2Form.resetFields();
          });
      });
    } catch (e: any) {
        const errorType = e?.response?.data?.type;
        setNotification({
          message: `${
            errorType === "Duplicate_indexed_field"
              ? `Project ID: ${project?.shortId} already exists in CaptionHub and cannot be sent again. Please refer to CaptionHub.`
              : `We were unable to send Project with ID: ${project?.shortId} to CaptionHub. Please close the wizard and try again or contact support.`
          }`,
          loadError: false,
          fail: true,
        });
    }
  };

  async function validateDestinationFolder( path:string ):Promise<boolean> {
    return await new Promise(( accept ) => {
      setDestinationValidating(true)
      socket.emit( "validateFolder", path, ( res:boolean ) => {

        return accept( res );
      })
    })
  }

  useEffect(() => {
    if ( !destinationFolder ) return;
    validateDestinationFolder(destinationFolder)
        .then(  ( res:boolean ) => {
            setDestinationValidating(false );
            setDestinationValid( res );
        })
  }, [destinationFolder])


  // Stores the information the user has completed in the current step and then
  // moves the wizard to the next step
  const moveNext = (values: any) => {
    switch (current) {
      case 0:
        prepareDocumentList(values);
        break;
      case 1:
        setDocumentList(values.documentList);
        prepareUpdateList(values.documentList);
        break;

    }

    // Do not go to step 3 until step 2 has validated
    // if (current !== 1) {
    setCurrent(current + 1);
    // }

  }

  // UI functions

  // Handles the user clicking the next button, submits the relevant form for the step the user
  // is on, if submission is successful the moveNext function will be triggered by the form
  // validation
  const next = () => {

    switch (current) {
      case 0:
        step1Form.submit();
        break;
      case 1:
        step2Form.submit();
        break;
    }

  };

  // Moves the wizard back one step
  const prev = () => {
    setCurrent(current - 1);
  };

  // Initialises the wizard, all values should be set to there starting
  // state in this function
  const initialiseWizard = () => {
    setNotification({
      message: ``,
      loadError: false,
      fail: false
    })

    // Reset the wizard state and progress bar
    setCurrent(0);
    setProject(null);
    setDocumentList([]);
    setUpdateList([]);
    setProgressPercent(0);
    setProgressColor(SUCCESS_COLOR);
  }

  // Displays the modal and retrieves the details of the project the wizard is
  // associated with
  const startWizard = async () => {
    try {

      // Set the modal to loading and display
      setOpen(true);

      setLoading( true );
      await getAutomations();
      setLoading( false );

      // Initialise wizard values
      initialiseWizard();

      // Retrieve the project data
      await retrieveProject();

    } catch (error) {
      setNotification({
        message: 'We were unable to retrieve the project information at this time. Please close the wizard and try again or contact support.',
        loadError: true,
        fail: true
      })
    } finally {
      // Remove the loading display
      setLoading(false);
    }


  };

  const getInitialValueForDocument = (field: any) => {

    let document = step2Form.getFieldValue("documentList")[field.key];

    if (document) {
      [document] = projectDoc.documents.filter((doc: any) => document.id === doc._id);
      if (document) {
        const links = extractGoogleDriveURLs(document.description, false);

        if (links.length) {
          const [{ drive }] = links;
          if (drive) {
            return drive;
          }
        }
      }
    }

    return null
  }

  // Closes the wizard modal and resets the values of the wizard so that it is
  // ready for the next open
  const handleCancel = () => {

    // Close the modal
    setOpen(false);

    // Call onClose handler
    if (onWizardClose) {
      onWizardClose(true);
    }

    // Set up the wizard for the next open
    initialiseWizard();
  };

  const onSelect = (value: any, option: any) => {
      if (!option.value) return;
      validateDestinationFolder(option.value)
        .then((res: boolean) => {
          setDestinationValidating(false);
          setDestinationValid(res);
        })
  };

  const onSearch = async (value: any) => {
    setDestinationValid(false);
  };

  if ( projectDoc.captionHubProject ) {
    return null;
  }

  return (
    <div style={{ padding: "0 15px" }}>
      <Button icon={<BuildOutlined />} onClick={startWizard} className="action-btn edit-btn" disabled={varStatus === "INVOICE" && !canEditProjectInvoice}>
        Send to CaptionHub
      </Button>
      <Modal
        forceRender
        open={open}
        destroyOnClose={true}
        onCancel={handleCancel}
        footer={[
          <div key='footer'>
            <Button
              onClick={handleCancel}
            >
              Cancel
            </Button>

            {current > 0 && (
              <Button
                onClick={prev}
              >
                Previous
              </Button>
            )}

            {current < NUM_WIZARD_STEPS - 1 && (
              <Button
                type="primary"
                disabled={!destinationValid}
                onClick={next}
              // loading={confirmLoading}
              >
                Next
              </Button>
            )}

            {current === NUM_WIZARD_STEPS - 1 &&
              (
                <Button
                  type="primary"
                  onClick={createProject}
                  // loading={confirmLoading}
                  disabled={notification.fail || updateList.filter( (doc:any) => typeof( doc.meta ) === "string" ).length}
                >
                  Create Project
                </Button>
              )}


          </div>
        ]}
        width={1100}
        closable={true}
        keyboard={false}
        maskClosable={true}
        title={"Send to CaptionHub"}
        className={"captionhub-wizard-wrap"}
      >
        {/* @ts-ignore */}
        <Steps current={current}>
          <Step title={'Step 1'} description={'Enter Project Level Information'} />
          <Step title={'Step 2'} description={'Link Documents to Source Video file'} status={current === 1 && notification.fail ? "error" : undefined} />
          <Step title={'Step 3'} description={'Review Settings'} />
        </Steps>

        <div className="step-content">
          {loading && (
            <div className={"loading"}>
              <Logo animate={true} />
              <div>Please Wait...</div>
            </div>
          )}

          {notification.loadError && (
            <div className={"error"}>
              <WarningOutlined className="error-icon-large" />
              <Text type="danger">{notification.message}</Text>
            </div>
          )}


          {(!loading && !notification.loadError) && (
            <>
              {current === 0 && (
                <Form
                  name="Step1"
                  autoComplete="off"
                  form={step1Form}
                  key={"step1Form"}
                  onFinish={moveNext}
                  className="list-form"
                  initialValues={{ projectDetails: project }}
                >
                  <div className="list-form-header-wrap">

                    <Row className="list-form-header-row">
                      <Col span={3}>
                        <div className="list-form-label">
                          {'Project Title: '}
                        </div>
                      </Col>
                      <Col span={21}>
                        <div className="project-title">
                          {project?.shortId} - {project?.title}
                        </div>
                      </Col>
                    </Row>

                    <Row className="list-form-header-row">
                      <Col span={3}>
                        <div className="list-form-label">
                          {'Description: '}
                        </div>
                      </Col>
                      <Col span={21}>
                        <Form.Item name="captionHubDescription" className="list-form-item" initialValue={projectDoc?.description}>
                          <TextArea rows={3} autoSize={{ minRows: 3, maxRows: 6 }} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="list-form-header-row">
                      <Col span={3}>
                        <div className="list-form-label">
                          {'Notes: '}
                        </div>
                      </Col>
                      <Col span={21}>
                        <Form.Item
                          name="captionHubNotes"
                          className="list-form-item"
                        >
                          <TextArea rows={3} autoSize={{ minRows: 3, maxRows: 6 }} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="list-form-header-row">
                      <Col span={3}>
                        <div className="list-form-label">
                          {'Automation: '}
                        </div>
                      </Col>
                      <Col span={21}>
                        <Form.Item
                          name="captionHubAutomation"
                          rules={[
                            {
                              required: false,
                              message: "Please select a CaptionHub Automation",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            placeholder="Please select a CaptionHub Automation"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              // @ts-ignore
                              option.children.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {automations.map((a: any) => {
                              return (
                                <Option key={a?.id} value={a?.value}>
                                  {a.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="list-form-header-row">
                      <Col span={3}>
                        <div className="list-form-label-switch">
                          {'Video Encoding: '}
                        </div>
                      </Col>
                      <Col span={21}>
                        <Form.Item
                          name="captionHubVideoEncoding"
                          className="list-form-item"
                          initialValue={false}
                        >
                          <Switch defaultChecked={false} />
                        </Form.Item>
                        <small>Check if this is an encoded video and is required as a deliverable. Note: You will need to upload the video manually to the delivery folder.</small>
                      </Col>
                    </Row>

                    <Row className="list-form-header-row">
                      <Col span={3}>
                        <div className="list-form-label">
                          {'Delivery Folder: '}
                        </div>
                      </Col>
                      <Col span={21}>
                        <Form.Item
                          name="captionHubDeliveryFolder"
                          rules={[
                            {
                              required: false,
                              message: "Required",
                            },
                          ]}
                          className="list-form-item"
                          initialValue={destinationFolder}
                        >
                          {
                            <AutoComplete
                              showSearch
                              allowClear
                              options={folderOptions}
                              placeholder="Please select or enter a Delivery Folder"
                              onChange={setDestinationFolder}
                              defaultValue={destinationFolder}
                              onSelect={onSelect}
                              onSearch={onSearch}
                            />
                          }
                          {
                            destinationValidating && <span>Validating...</span>
                          }
                          {
                            !destinationValidating && destinationValid && destinationFolder && <span>The destination folder: <i><a href={destinationFolder!}>{destinationFolder}</a> </i> is valid</span>
                          }
                          {
                            !destinationValidating && !destinationValid && destinationFolder && <span>The destination folder {destinationFolder} does not exist or is invalid. Please ensure this folder exists before continuing.</span>
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                  </div>
                </Form>
              )}

              {current === 1 && (
                <Form
                  name="Step2"
                  autoComplete="off"
                  form={step2Form}
                  key={"step2Form"}
                  onFinish={moveNext}
                  initialValues={{ documentList: documentList }}
                  layout="vertical"
                  className="list-form"
                >
                  <Form.List
                    name="documentList"
                  >
                    {fields => (
                      <div key='documentListWrapper' className="list-form-header-wrap">
                        {notification.fail && (
                          <Row className="list-form-row">
                            <Text type="danger">{notification.message}</Text>
                          </Row>
                        )}
                        <Row
                          key="headerRow"
                          className="list-form-row"
                        >
                          <Col span={12}>
                            Document Title
                          </Col>
                          <Col span={12}>
                            Drive Link
                          </Col>
                        </Row>
                        {fields.map((field) => {
                          const fieldValue = step2Form.getFieldValue("documentList")[field.key];

                          return <Row
                            key={field.key}
                            className="list-form-row"
                          >
                            <Col span={12}>
                              <div className="list-form-label">
                                {fieldValue.title}
                              </div>
                            </Col>
                            <Col span={12}>
                              <Row>
                                <Col span={23}>

                                  <Form.Item
                                    name={[field.name, "driveLink"]}
                                    rules={[
                                      { required: false, message: "Required" },
                                      // documents with the same language cannot have the same drive link associated.
                                      ({ getFieldValue }) => ({
                                        validator(record, value) {

                                          const driveLinkFilter = step2Form.getFieldsValue().documentList.filter(
                                            (item: any) =>
                                              item?.targetLanguageCode === fieldValue.targetLanguageCode &&
                                              item?.driveLink === value
                                          )

                                          if (driveLinkFilter.length === 1) {
                                            return Promise.resolve();
                                          } else if (!value) {
                                            return Promise.reject(new Error(`Required`));
                                          } else {
                                            return Promise.reject(
                                              new Error(`This link already exists for ${fieldValue.targetLanguageCode}, please enter another link for this document`)
                                            );
                                          }
                                        },
                                      }),
                                    ]}
                                    className="list-form-item"
                                    initialValue={getInitialValueForDocument(field)}
                                  >
                                    {
                                      <AutoComplete
                                        showSearch
                                        allowClear
                                        options={driveOptions}
                                        placeholder="Please select or enter a Drive Link"
                                      />
                                    }
                                  </Form.Item>
                                </Col>
                                <Col span={1}>
                                  {<Button type='text' className="icon-button" onClick={() => {
                                    const fields = step2Form.getFieldsValue()
                                    const { documentList } = fields
                                    for (let documentCount = field.key + 1; documentCount < documentList.length; documentCount++) {
                                      documentList[documentCount].driveLink = documentList[field.key].driveLink;
                                    }
                                    step2Form.setFieldsValue({ documentList });
                                    step2Form.validateFields();
                                  }}>
                                    <ArrowDownOutlined />
                                  </Button>}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        })}
                      </div>
                    )}
                  </Form.List>
                </Form>
              )}

              {current === 2 && (
                <div className="last-step-container last-step-container__matched">
                  <div className="list-form">
                    <div className="list-form-header-wrap">
                      <Row className="list-form-row">
                        {notification.message ? (
                          <Text type={notification.fail ? "danger" : undefined}>{notification.message}</Text>
                        ) : (
                          <Text>These projects will be created in CaptionHub</Text>
                        )}
                        <Progress
                          percent={progressPercent}
                          strokeColor={progressColor}
                          showInfo={false}
                          style={{ marginBottom: '5px' }}
                        />
                      </Row>
                      {updateList.filter( (doc:any) => typeof( doc.meta ) === "string" ).map((documentListItem: CaptionHubDetail) => (
                        <Row key={documentListItem.driveLink}>
                          <Col span={4} />
                          <Col span={20}>
                              <span >{documentListItem.driveLink}</span><br />
                              <Alert message={documentListItem.meta} type="error" />
                          </Col>
                          <Col span={4}>
                            <h3>Document(s)</h3>
                          </Col>
                          <Col span={20}>
                            {
                              projectDoc.documents.filter( ( doc:any ) => documentListItem.documents.indexOf(doc._id) !== -1).map((item: any) => (
                                  <p key={item?.id}>{item?.title}</p>)
                              )
                            }

                          </Col>
                        </Row>
                      ))}
                      {updateList.filter( (doc:any) => typeof( doc.meta ) !== "string" ).map((documentListItem: CaptionHubDetail) => (
                        <Row
                          key={documentListItem.driveLink}
                        >
                          <Row className="list-form-row">
                            <Col span={4}>
                              {
                                documentListItem.meta.thumbnailLink
                                    ? <img src={documentListItem.meta.thumbnailLink}  alt={"Drive Link"} crossOrigin={"anonymous"} width={"100%"} style={{padding: "0 10px 0 0"}}/>
                                    : "Drive Link"
                              }
                            </Col>
                            <Col span={20}>
                              <a href={documentListItem.driveLink} >{documentListItem.driveLink}</a><br />
                              <small>
                                Title: {documentListItem.meta.title}, <br />
                                Runtime: {documentListItem.meta.durationMillis/1000}s, <br />
                                File Type: {documentListItem.meta.mimeType}, <br />
                                Size: {documentListItem.meta.fileSize} bytes, <br />
                                Resolution: { documentListItem.meta.width }/{documentListItem.meta.height}, <br />
                                Created: { new Date(documentListItem.meta.createdDate).toLocaleDateString() }, <br />
                                Updated: { new Date(documentListItem.meta.modifiedDate).toLocaleDateString() } <br />
                              </small>
                              <br />
                            </Col>
                            <Col span={4}>
                              <h3>Document(s)</h3>
                            </Col>
                            <Col span={20}>
                              {
                                projectDoc.documents.filter( ( doc:any ) => documentListItem.documents.indexOf(doc._id) !== -1).map((item: any) => (
                                    <p key={item?.id}>{item?.title}</p>)
                                )
                              }

                            </Col>
                          </Row>
                        </Row>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

        </div>
      </Modal >
    </div >
  );
};
