import {useCallback, useEffect, useState} from "react";
import {
  Button,
  Form,
  Modal,
  Select,
  Switch,
  DatePicker,
  Checkbox,
  Row,
  Col,
  InputNumber
} from "antd";
import moment from 'moment';
import dayjs from 'dayjs';
import { EditOutlined } from "@ant-design/icons";
import {
  successfulNotification,
  failedNotification,
} from "../utils/notifications";
import { useMercuryContext } from "../user-context";
import {
  FiniteDropDownWithAsyncLoader,
  FiniteDropDownWithAsyncLoaderItem
} from "../stories/FiniteDropDown/FiniteDropDownWithAsyncLoader.abstract";

import './BatchEditing.scss'
import { usePermissions } from "../hooks/usePermissions";

class WorkflowDetails extends FiniteDropDownWithAsyncLoader {
  async fetch(signal: any): Promise<FiniteDropDownWithAsyncLoaderItem[]> {

    const { data } = await this.props.context.gpClient.get( "/google-gp-v1/WorkflowType");

    return data.map( ( item: any ) => {
      return {
        value: item._id,
        label: item.title
      }
    });
  }

}

class PODetails extends FiniteDropDownWithAsyncLoader {
  async fetch(signal: any): Promise<FiniteDropDownWithAsyncLoaderItem[]> {

    const { data } = await this.props.context.gpClient.get( "/google-gp-v1/POType/paginate?page=1&limit=500&query[isActive][0]=true");

    return data[0]?.documents?.map( ( item: any ) => {
      return {
        value: item._id,
        label: item.title
      }
    });
  }

}

const BatchEditing = (props: {
  options?: [];
  selectedRows: any;
  reload: () => any;
  bust: () => void
}) => {
  const context = useMercuryContext();
  const { client } = context;
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const selectedRows = props.selectedRows;
  const [workflowType, setWorkflowType] = useState<boolean>(false);
  const [poType, setPOType] = useState<boolean>(false);
  const [expedited, setExpedited] = useState<boolean>(false);
  const [noExtension, setNoExtension] = useState<boolean>(false);
  const [launched, setLaunched] = useState<boolean>(false);
  const [onHold, setOnHold] = useState<boolean>(false);
  const [poIssued, setPoIssued] = useState<boolean>(false);
  const [poNumber, setPoNumber] = useState<boolean>(false);
  const [salesOrder, setSalesOrder] = useState<boolean>(false);
  const [invoiceMonth, setInvoiceMonth] = useState<boolean>(false);
  const [projectApprovalStatus, setProjectApprovalStatus] = useState<boolean>(false);
  const [cc, setCC] = useState<boolean>(false);
  const [costCodes, setCostCodes] = useState<any[]>([]);
  const [costCodesLoading, setCostCodeLoading] = useState<boolean>(true);
  const [isToday, setIsToday] = useState<boolean>(false);
  const rows = selectedRows || [];
  const { hasPermissions } = usePermissions();
  const canEditProjectInvoice = hasPermissions([{ customerApplication: "Google", permissionName: "Invoice Project", action: "edit" }]);
  const allowedSubmitCriteria = (element: any) => element.varStatus !== "INVOICE";
  const allowSubmit = rows.length > 0 && rows.filter( ( e: any ) => e.deleted !== true ).filter(allowedSubmitCriteria).length;

  const getCCS = useCallback(async () => {

    try {
      const { data } = await client.get("/core-ccs-v1");
      setCostCodes(data?.map( ( item:any ) => {
                  return {
                    _id: item._id,
                    title: item.code,
                    value: item.code
                  }
                }))
      setCostCodeLoading(false);

    } catch (error: any) {
      console.error('Error fetching CCS: ', error);
      setCostCodeLoading(false);
      setCostCodes([]);
    }
  }, [context.client]);

  useEffect(() => {
    getCCS()
    return () => {
      setCostCodeLoading(false)
      setCostCodes([])
    }
  }, [getCCS])

  const batchEdit = async (values: any ) => {

    try {
      // Check if form was submitted or cancelled
      setLoading(true);

      const customVendorProjectMetadata = {
        workflowType: workflowType ? values.workflowType : undefined,
        poType: poType ? values.poType : undefined,
        expedited: expedited ? values.expedited : undefined,
        expeditedRequest: values.expedited ? "Client" : undefined,
        noExtension: noExtension ? values.noExtension : undefined,
        launched: launched ? values.launched : undefined,
        onHold: onHold ? values.onHold : undefined,
        POIssued: poIssued ? values.poIssued : undefined,
        PODate: poIssued ? moment(values.PODate.toDate()).startOf('month') : undefined,
        PONumber: poNumber ? values.poNumber.toString() : undefined,
        salesOrder: salesOrder ? values.salesOrder : undefined,
        invoiceMonth: invoiceMonth ? moment(values.invoiceMonth.toDate()).startOf('month') : undefined,
        costCode: cc ? values.costCode : undefined,
        projectApprovalStatus: projectApprovalStatus ? values?.projectApprovalStatus : undefined,
      } as Record<any, any>

      return await new Promise((accept, reject) => {

        const shortIdsToEmit = selectedRows
        ?.filter((p: any) => (canEditProjectInvoice && p.varStatus === "INVOICE") || allowedSubmitCriteria(p))
        .map((p: any) => p.shortId);

        context.gpSocket.project.emit("batchUpdate", {
              shortIds: shortIdsToEmit,
              customVendorProjectMetadata
        },
        (e: any) => {
          const firstSuccessIds = e.successIds.slice(0, 10);
          const moreSuccessIds = e.successIds.length - firstSuccessIds.length;
          const firstFailureIds = e.failureIds.slice(0, 10);
          const moreFailureIds = e.failureIds.length - firstFailureIds.length;
          setLoading( false )
          setConfirmLoading( false )
          
          if (e.successIds.length > 0) {
            successfulNotification(
                `Project ID(s): ${firstSuccessIds.join(", ")} have been updated ${e.successIds.length > 10 ? '... ' + moreSuccessIds + ' more' : ''}`,
                "Invoiced projects will not be updated unless you have permission."
            )
            setIsOpen(false);
            form.resetFields();
            resetCheckboxes();
            return accept(null)
          } else {
            failedNotification(
                `Failed to bulk update:`,
                `Project ID(s): ${firstSuccessIds.join(", ") || firstFailureIds.join(", ")} have failed to update ${e.successIds.length > 10 || e.failureIds.length > 10 ? '... ' + moreSuccessIds || moreFailureIds + ' more' : ''}`
            )
            return reject(e as Error)
          }

        });
      })
    }
    catch( err:any ) {
      console.error(err)
    }
  };

  const resetCheckboxes = () => {
    setWorkflowType(false);
    setPOType(false);
    setExpedited(false);
    setNoExtension(false);
    setLaunched(false);
    setOnHold(false);
    setPoIssued(false);
    setPoNumber(false);
    setSalesOrder(false);
    setInvoiceMonth(false);
    setCC(false);
    setProjectApprovalStatus(false);
  }

  const handlePODateChange = (PODate: any | null) => {
   if (PODate) {
      setIsToday(true);
      form.setFieldsValue({ poIssued: true });
    } else {
      setIsToday(false);
      form.setFieldsValue({ poIssued: false });
      form.setFieldsValue({ PODate: null });
    }
  };

  const handlePOIssuedChange = (checked: boolean) => {
    if (checked) {
      form.setFieldsValue({ PODate: dayjs() });
    } else {
      form.setFieldsValue({ PODate: null });
    }
    setIsToday(checked);
  };


  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className="action-btn edit-btn"
        icon={<EditOutlined />}
        type="primary"
        loading={loading}
        disabled={!allowSubmit && !canEditProjectInvoice || rows.length === 0}
      >
        Edit
      </Button>
      <Modal
        open={isOpen}
        className="batch-modal"
        title="Batch Update"
        maskClosable={false}
        okText="Ok"
        cancelText="Cancel"
        onCancel={async () => {
          resetCheckboxes();
          setIsOpen(false);
        }}
        onOk={async () => {
          try {
            if (await form.validateFields()) {
              let values = form.getFieldsValue();
              setConfirmLoading(true);
              console.log('values', values)
              await batchEdit(
                values
              );
            }
          } catch (error) {
            console.error("Validate Failed:", error);
          }
          finally {
            await props.reload();
          }
        }}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 18,
          }}
          labelAlign="left"
          name="batchEditingForm"
        >
          <p>
            Specify the fields to be updated on the selected projects using the
            checkboxes.
          </p>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                name="workflowType"
                label="Workflow Type"
                style={ !workflowType ? { pointerEvents:'none'} : undefined}
                rules={[
                  {
                    required: false,
                    message: "Please select an Workflow Type",
                  },
                ]}
              >
                <WorkflowDetails
                  showSearch
                  allowClear
                  placeholder="Select Workflow Type"
                  // optionFilterProp="children"
                  // disabled={!workflowType}
                  context={context} id={"workflowTypeSelect"} name={"workflow"}/>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setWorkflowType(e.target.checked)}
                />
              </Form.Item>
            </Col>
            <Col span={22}>
              <Form.Item
                name="poType"
                label="PO Type"
                style={ !poType ? { pointerEvents:'none'} : undefined}
                rules={[
                  {
                    required: false,
                    message: "Please select an PO Type",
                  },
                ]}
              >
                <PODetails
                  showSearch
                  allowClear
                  placeholder="Select PO Type"
                  context={context} id={"poTypeSelect"} name={"poType"}/>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setPOType(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="Expedited"
                name="expedited"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch disabled={!expedited} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setExpedited(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="No Extension"
                name="noExtension"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch disabled={!noExtension} />
              </Form.Item>
            </Col>

            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setNoExtension(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="Launched"
                name="launched"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch disabled={!launched} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setLaunched(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="On Hold"
                name="onHold"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch disabled={!onHold} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setOnHold(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="PO Issued"
                name="poIssued"
                initialValue={false}
              >
                <Switch disabled={!poIssued} checked={isToday} onChange={handlePOIssuedChange} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Checkbox
                  defaultChecked={false}
                  checked={poIssued}
                  onChange={(e) => setPoIssued(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item label="PODate" name="PODate">
                <DatePicker
                  picker="month"
                  format={"MM-YYYY"}
                  disabled={!poIssued}
                  onChange={handlePODateChange}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Checkbox
                  defaultChecked={false}
                  checked={poIssued}
                  onChange={(e) => setPoIssued(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="PO Number"
                name="poNumber"
                valuePropName="checked"
                initialValue={false}
              >
                <InputNumber
                    min={1}
                    disabled={!poNumber}
                    step="1"
                    style={{width: '100%'}}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setPoNumber(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Dummy data - New, WIP, Invoiced and Canceled */}
          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                name="salesOrder"
                label="Sales Order"
                rules={[
                  {
                    required: false,
                    message: "Please select an Workflow Type",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Sales Order"
                  disabled={!salesOrder}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    // @ts-ignore
                    option.children.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[
                    { _id: "1", title: "New", value: "New" },
                    { _id: "2", title: "WIP", value: "WIP" },
                    { _id: "3", title: "Invoiced", value: "Invoiced" },
                    { _id: "4", title: "Canceled", value: "Cancelled" }
                  ].map((so: any) => {
                    return (
                        <Option key={so?._id} value={so?.value}>
                          {so.title}
                        </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setSalesOrder(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item label="Invoice Month" name="invoiceMonth">
                <DatePicker
                  picker="month"
                  format={"MM-YYYY"}
                  disabled={!invoiceMonth}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setInvoiceMonth(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item label="Empower CC" name="costCode">
                <Select
                    showSearch
                    allowClear
                    placeholder="Select Cost Code"
                    disabled={!cc && !costCodesLoading}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        // @ts-ignore
                        option.children.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                  {costCodes.map((so: any) => {
                    return (
                        <Option key={so?._id} value={so?.value}>
                          {so.title}
                        </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                    defaultChecked={false}
                    onChange={(e) => setCC(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                name="projectApprovalStatus"
                label="Project Approval Status"
                rules={[
                  {
                    required: false,
                    message: "Please select project approval status",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Project Approval Status"
                  disabled={!projectApprovalStatus}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    // @ts-ignore
                    option.children.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[
                    { _id: "1", title: "Pending", value: "Pending" },
                    { _id: "2", title: "Approved", value: "Approved" },
                  ].map((so: any) => {
                    return (
                        <Option key={so?._id} value={so?.value}>
                          {so.title}
                        </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setProjectApprovalStatus(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Modal>
    </>
  );
};

export default BatchEditing;
