import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { defaultHeaders } from "../api/APIUtils";
import { getFileExtension, removeSpecifiedExtension } from "../utils/helpers";
import JSZip from "jszip";
import { v4 as uuidv4 } from "uuid";
import { usePermissions } from "../hooks/usePermissions";

import {
  Typography,
  Upload,
  Modal,
  Button,
  Checkbox,
  Table,
  Spin,
  Dropdown,
  Tooltip,
} from "antd";

import {
  CloudUploadOutlined,
  UploadOutlined,
  DownOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import {
  successfulNotification,
  failedNotification,
  infoNotification,
} from "../utils/notifications";
import { useNavigate } from "react-router-dom";
import { AutoUploadModal } from "./AutoUploadModal";
import { useMercuryContext } from "../user-context";

const UploadBtn = (props: any) => {
  let navigate = useNavigate();

  const redirectToProject = useCallback(() => {
    if (navigate) {
      navigate(`/project/${props.shortId}?type=shortId`);
    }
  },[navigate, props.shortId]);

  const [filesList, setFilesList] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState<any>({ hasError: false, errorMsg: "" });
  const [dtpFiles, setDtpFiles] = useState<any>([]);
  const [loadingDtpFiles, setLoadingDtpFiles] = useState(false);
  const [projectLink, setProjectLink] = useState<any>();
  const [colorStatus, setColorStatus] = useState(false);

  const [loadingAutoupload, setLoadingAutoupload] = useState(false);
  const [autoUploadModal, setAutoUploadModal] = useState(false);
  const [autoUploading, setAutoUploading] = useState(false);

  const { Text, Link }: any = Typography;
  const { Dragger } = Upload;

  const context = useMercuryContext();
  const { hasPermissions } = usePermissions();
  const canEditProjectInvoice = hasPermissions([{ customerApplication: "Google", permissionName: "Invoice Project", action: "edit" }]);

  const getDtpFiles = useCallback(async () => {
    try {
      const projectEndpoint = `${props.baseURI}/projects/${props.shortId}?type=shortId`;

      setLoadingDtpFiles(true);

      await axios
        .get(projectEndpoint, {
          headers: defaultHeaders(props.accessToken),
        })
        .then((files) => {
          const allFiles = files.data.files;
          setDtpFiles(allFiles);
        });
      setLoadingDtpFiles(false);
    } catch (error) {
      console.error(error);
      failedNotification(
        "Failed to load DTP Files",
        "Please refresh and try again."
      );
    }
  }, [props.accessToken, props.baseURI, props.shortId]);

  useEffect(() => {
    if (props.dtpFiles) {
      setDtpFiles(props.dtpFiles);
    } else {
      // fetch project details and extract dtp files
      visible && getDtpFiles();
    }
    setProjectLink(
      <div>
        <Button
          type="link"
          onClick={redirectToProject}
          style={{ padding: "0", fontWeight: "bold" }}
        >
          View project details ({props.shortId})
        </Button>
      </div>
    );
  }, [props.dtpFiles, getDtpFiles, visible, props.shortId, redirectToProject]);

  // removes a file before uploading
  const handleRemove = (file: any) => {
    const newFilesList = filesList.filter((item: any) => item.uid !== file.key);

    setFilesList([...newFilesList]);

    if (file.tempFileType === 'TRANSLATED') {
      const atLeastOneFileHasData = newFilesList.some((file:any) => file.matchedTo && file.matchedWithOtherXliff?.matchedXliffFiles.length > 0);
      const allUploadedAreMatched = atLeastOneFileHasData && newFilesList.every((file:any) => file.matchedTo && file.matchedWithOtherXliff?.triedToMatchXliffs);
      newFilesList.length > 0 && !autoUploadModal && !autoUploading && allUploadedAreMatched && setAutoUploadModal(true)
    }

    if (error.hasError) {
      setError({ ...error, hasError: false });
    }
  };

  // removes all files before uploading
  const handleRemoveAll = () => {
    setFilesList([]);
    if (error.hasError) {
      setError({ ...error, hasError: false });
    }
  };

  interface XliffItem {
    _id: string;
  }
  
  const sendMatchingXliffsRequest = async (id:string) => {
    const promise = context.gpClient.post(`/google-gp-v1/dtp/autoupload/${id}`);
    const timeoutPromise = new Promise((resolve, reject) => 
      setTimeout(() => reject(new Error('Request timed out - forced')), 2000)
    );

    const response = await Promise.race([promise, timeoutPromise]);
      return response;
  }

  const getMatchingXliffs = async (id: string, retryCount: number = 2): Promise<XliffItem[]> => {
    setLoadingAutoupload(true);

    const attemptRequest = async (retryRemaining: number): Promise<XliffItem[]> => {
      try {

        const response = await sendMatchingXliffsRequest(id);

        setLoadingAutoupload(false);

        return response.data.length > 0 ? response.data.filter((item: XliffItem) => item._id !== id) : [];
      } catch (error) {
        console.error('Error or timeout during fetch', error);
        if (retryRemaining > 0) {
          console.log(`Retrying... Attempts left: ${retryRemaining - 1}`);
          return attemptRequest(retryRemaining - 1);
        } else {
          setLoadingAutoupload(false);
          infoNotification("Failed to match", "Please proceed without matching with additional xliffs.");
          return [];
        }
      }
    };
  
    return attemptRequest(retryCount);
  };

  // table dropdown matched to selection
  const handleSelectMenu = async (key: any, uploadedFileId: any, dtpFiles: any, uploadedFilename:any, fileType:string, allUploadedAreMatched: boolean) => {
    // get the id of the new dtp file
    const selectedFileId = key;

    // loop through all dtp files and get all details for selected Id
    const newMatchedTo = dtpFiles.find(
      (item: any) => item._id === selectedFileId
    );

    // loop through filesList and find the item
    const file: any = filesList.find(
      (item: any) => item.uid === uploadedFileId
    );

    // update selected key to pass as default value to dropdown
    file.selectedMatchToKey = selectedFileId;
    // update matched to with new details
    file.matchedTo = newMatchedTo;

    if (props.projectStatus === "IN_POSTPROCESSING" && fileType === 'TRANSLATED' && file.matchedTo) {
      const matchedXliffFiles = await getMatchingXliffs(file.matchedTo?._id);

      file.matchedWithOtherXliff = {uploadedFilename: uploadedFilename, matchedXliffFiles: matchedXliffFiles.map((i:any) => { return {...i, key: i.id}}), triedToMatchXliffs: true}
      
      !autoUploading && !autoUploadModal && matchedXliffFiles.length > 0 && allUploadedAreMatched && setAutoUploadModal(true)
    }


    setFilesList([...filesList]);
  };

  // logic to calculate what options will be displayed inside matched to dropdown
  const calculateDropdownOptions = (list: any, record: any) => {
    let finalList = list;

    // display only translated files if the project is IN POSTPROCESSING
    // and uploaded file is TRANSLATED
    if (
      record.tempFileType === "TRANSLATED" &&
      props.projectStatus === "IN_POSTPROCESSING"
    ) {
      finalList = list.filter((item: any) => item.fileType === "TRANSLATED");
    }

    const finalSort = (a: any, b: any) => {
      if (a.languageCode > b.languageCode) {
        return 1;
      } else {
        if (a.languageCode === b.languageCode) {
          if (a.displayName > b.displayName) {
            return 1;
          } else {
            return -1;
          }
        }
        return -1;
      }
    };

    // sort files by language code and display name
    finalList.sort((a: any, b: any) => finalSort(a, b));
    return finalList;
  };

  const determineMenuItems = (record: any) => {
    const availableItems = calculateDropdownOptions(dtpFiles, record);
    const allUploadedAreMatched = filesList.filter((file:any) => file.matchedTo).length + 1 === filesList.length;

    return availableItems.map((item: any) => {
      return {
        label: (
          <button
            className={
              item._id === record.selectedMatchToKey
                ? "menu-item-button isActive"
                : "menu-item-button"
            }
            onClick={() => handleSelectMenu(item._id, record.uid, dtpFiles, record.name, item.fileType, allUploadedAreMatched)}
          >
            {`${item.languageCode} - ${item.displayName}`}
          </button>
        ),
        key: item._id,
      };
    });
  };

  const uploadedFileStatus = (record: any) => {
    if (record.status === "error") {
      setColorStatus(true);
      return "danger";
    } else if (record.status === "done") {
      setColorStatus(false);
      return "success";
    } else {
      return "";
    }
  };

  const uploadColumns: any = [
    {
      title: "Uploaded file",
      dataIndex: "name",
      render: (text: any, record: any) => (
        <>
          {record.status === "uploading" && (
            <Spin size="small" style={{ marginRight: "10px" }} />
          )}

          <PaperClipOutlined
            style={{
              marginRight: "5px",
              color: colorStatus ? "var(--red)" : "var(--tertiary-color)",
            }}
          />
          <Text type={() => uploadedFileStatus(record)}>
            {record.wasZip ? record.nameWithZip : text}
          </Text>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "tempFileType",
      render: (text: any) => text,
    },
    {
      title: "Matched To",
      dataIndex: "matchedTo",
      width: 350,
      render: (text: any, record: any) => {
        const menuItems =
          props.projectStatus === "IN_POSTPROCESSING" &&
          record.matchedTo &&
          record.tempFileType === "TRANSLATED"
            ? []
            : determineMenuItems(record);
        return (
          <>
            {props.projectStatus !== "IN_PREPROCESSING" &&
              record.tempFileType === "SOURCE" && (
                <span style={{ marginRight: "5px" }}>
                  <Tooltip
                    overlayInnerStyle={{ width: "250px" }}
                    color="#0096d6"
                    title={`Source files can be uploaded only if the project status is: IN PREPROCESSING`}
                    placement={"left"}
                  >
                    <InfoCircleOutlined style={{ color: "#0096d6" }} />
                  </Tooltip>
                </span>
              )}
            <Dropdown
              disabled={
                record.tempReference ||
                (props.projectStatus === "IN_PREPROCESSING" &&
                  record.tempFileType === "TRANSLATED") ||
                (props.projectStatus === "IN_POSTPROCESSING" &&
                  record.tempFileType === "SOURCE") // once a file has been matched automatically do not allow the user to rematch it to another source file for IN POSTPROCESSING
              }
              menu={{
                items: menuItems,
              }}
              trigger={["click"]}
              overlayClassName="match-menu"
            >
              {text ? (
                <Link type="success">
                  {text.displayName}
                  {props.projectStatus === "IN_POSTPROCESSING" &&
                  record.matchedTo &&
                  record.tempFileType === "TRANSLATED" ? (
                    ""
                  ) : (
                    <DownOutlined style={{ marginLeft: "5px" }} />
                  )}
                </Link>
              ) : (
                <Link type="danger">
                  Not Matched
                  <DownOutlined style={{ marginLeft: "5px" }} />
                </Link>
              )}
            </Dropdown>
          </>
        );
      },
    },
    {
      title: (
        <>
          {props.projectStatus === "IN_PREPROCESSING" &&
            filesList.length > 1 && (
              <Tooltip
                overlayInnerStyle={{ width: "160px" }}
                color="#0096d6"
                title={`Check / Uncheck all files as REFERENCE`}
                placement={"top"}
              >
                <Checkbox
                  onChange={(e) => handleChangeAllReference(e)}
                  style={{ marginRight: "5px", fontSize: "12px" }}
                />
              </Tooltip>
            )}
          Reference
        </>
      ),
      dataIndex: "tempReference",
      width: 120,
      align: "center",
      render: (text: any, record: any) =>
        // allow reference files only in preprocessing status
        props.projectStatus !== "IN_PREPROCESSING" ? (
          <Tooltip
            overlayInnerStyle={{ width: "250px" }}
            color="#0096d6"
            title={`Reference files can be uploaded only if the project status is: IN PREPROCESSING`}
            placement={"left"}
          >
            <InfoCircleOutlined style={{ color: "#0096d6" }} />
          </Tooltip>
        ) : (
          <Checkbox
            checked={text}
            onChange={(e) => handleChangeReference(e, record)}
          />
        ),
    },
    {
      title: (
        <>
          {filesList.length > 1 && (
            <Tooltip
              overlayInnerStyle={{ width: "160px" }}
              color="var(--red)"
              title={`Remove all uploaded files`}
              placement={"top"}
            >
              <DeleteOutlined
                onClick={() => handleRemoveAll()}
                style={{ marginRight: "5px" }}
              />
            </Tooltip>
          )}
          Remove
        </>
      ),
      dataIndex: "remove",
      width: 100,
      align: "center",
      render: (text: any, record: any) => (
        <DeleteOutlined onClick={() => handleRemove(record)} />
      ),
    },
  ];

  // separate intance to set default headers
  const uploadAxiosInstance: any = axios.create();

  uploadAxiosInstance.defaults.headers = {
    "Content-type": "application/octet-stream", // Need to set the content type otherwise S3 will reject
  };



  // calculate file type
  const calculateFileType = (filename: any, isReference: any) => {
    // extract file extension
    let fileExt = getFileExtension(filename);
    if (isReference) {
      return "REFERENCE";
    }

    if (
      !isReference &&
      (fileExt.toLowerCase() === ".xlf" || fileExt.toLowerCase() === ".xliff")
    ) {
      return "SOURCE";
    } else {
      // Extension doesn't indicate an XLIFF so search for a TRANSLATED file
      return "TRANSLATED";
    }
  };

  const matchFile = (dtpFiles: any, filename: any, language = null) => {
    const matched = dtpFiles.find((file: any) => {
      return (
        file.displayName.toLowerCase().startsWith(filename.toLowerCase()) &&
        (file.languageCode.toLowerCase() === language || language === null)
      );
    });
    return matched;
  };

  const handleReferenceType = () => {
    return null;
  };

  const handleSourceType = (fileName: string, fileExt: string, dtpList: any) => {
    if (props.projectStatus === "IN_POSTPROCESSING") {
        return null;
    }

    // remove the .xliff extension and .sdlxliff if it is present
    fileName = removeSpecifiedExtension(fileName, fileExt);
    fileName = removeSpecifiedExtension(fileName, ".sdlxliff");

    const matchedFile = matchFile(dtpList, fileName);
    return matchedFile || null;
};

  const handleTranslatedType = (fileName: string, fileExt: string, dtpList: any) => {
    if (props.projectStatus === "IN_PREPROCESSING") {
        return null;
    }

    let filenameWithLanguage = removeSpecifiedExtension(fileName, fileExt);
    if (filenameWithLanguage.indexOf(".") > 0) {
        filenameWithLanguage = filenameWithLanguage.substring(0, filenameWithLanguage.lastIndexOf("."));
    }

    if (filenameWithLanguage.lastIndexOf("_") < 0 && fileExt !== ".zip") {
        return null;
    }

    const searchLanguage = filenameWithLanguage.substring(filenameWithLanguage.lastIndexOf("_") + 1).toLowerCase();
    const filenameWithoutLanguage = filenameWithLanguage.substring(0, filenameWithLanguage.lastIndexOf("_"));
    let searchFilename = filenameWithoutLanguage + fileExt;

    let matchedFile = matchFile(dtpList, searchFilename, searchLanguage);
    if (!matchedFile) {
        searchFilename = filenameWithoutLanguage;
        matchedFile = matchFile(dtpList, searchFilename, searchLanguage);
    }

    return matchedFile;
  };

  // calculate files matching to dtp files list
  const calculateMatching = (fileType: string, fileName: string, dtpList: any) => {
    let file: any;

    let fileExt = getFileExtension(fileName);

    switch (fileType) {
      case "REFERENCE":
        file = handleReferenceType();
        break;
      case "SOURCE":
        file = handleSourceType(fileName, fileExt, dtpList);
        break;
      case "TRANSLATED":
        file = handleTranslatedType(fileName, fileExt, dtpList);
        break;
    }
    return file;
  };

  const calculateXml = async (file: any, fileName: any) => {
    let xmlFile = false;

    const reader = new FileReader();
    reader.readAsText(file);
    const result: any = await new Promise((resolve) => {
      reader.onload = function () {
        resolve(reader.result);
      };
    });

    if (
      (fileName.toLowerCase().includes(".xlf") ||
        fileName.toLowerCase().includes(".xliff")) &&
      result.includes('<?xml') &&
      result.includes('sdlxliff:support="xliff12polyglot"')
    ) {
      console.log("valid xml");
      xmlFile = true;
    }

    return xmlFile;
  };

  const handleZipFileErrors = (fileCount: number, z: any, zipEntry: any) => {

    const MAX_SIZE = 1000000000; // 1 GB
    const MAX_FILES = 10000;
    let targetDirectory = __dirname;

    let totalSize = 0;

    if (fileCount > MAX_FILES) {
      throw new Error('Reached max. number of files');
    }
    // Prevent ZipSlip path traversal (S6096)
    if (!targetDirectory) {
      throw new Error('Path traversal detected');
    }

    if (!z.file(zipEntry.name)) {
      return z;
    } else {
      z.file(zipEntry.name).async('nodebuffer').then(function (content: any) {
        totalSize += content.length;
        if (totalSize > MAX_SIZE) {
          throw new Error('Reached max. size');
        }
      });
    }

  }

  const extractZip = async (file: any) => {
    try {

      let fileCount = 0;
      let zip = new JSZip();
      let zipFiles: any;

      try {
        zipFiles = await zip.loadAsync(file).then(function (z: any) {
          zip.forEach(function (zipEntry: any) {
            fileCount++;
            handleZipFileErrors(fileCount, z, zipEntry);
          });
          return z;
        });
        console.log("Valid zip file");
      } catch (err) {
        console.log("Not a valid zip file", err);
      }

      const extractedFiles = [];

      for (let item of Object.entries(zipFiles.files)) {
        const fileName: any = item[0];
        const fileDetails: any = item[1];

        let checkExt = getFileExtension(fileName);

        // save the actual content and attach it to the uploaded file object
        let fileContent = await zip.files[fileName].async("blob");

        const newFile = {
          wasZip: true,
          nameWithZip: `${file.name}/${fileName}`, // displayed name format - files.zip/reference/reference.docx
          name: fileName.substring(fileName.lastIndexOf("/") + 1), // get only the file name without any folder name
          originFileContent: fileContent, // the actual content of the file
          uid: `zip-upload-${uuidv4()}`, //generates unique id for each file that was
          lastModified: file.lastModified, // pass data from zip file
          lastModifiedDate: file.lastModifiedDate, // pass data from zip file
          xmlValid:
            checkExt === ".xliff" || checkExt === ".xlf"
              ? await calculateXml(fileContent, fileName)
              : true,
        };

        // push only if is a file, ignore folders
        if (!fileDetails.dir) {
          extractedFiles.push(newFile);
        }
      }

      return extractedFiles;
    } catch (error) {
      console.error(
        `Error while unzipping the file: ${file.name}. See error on next line.`
      );
      console.log(error);
    }
  };

  // on click of all reference checkbox
  const handleChangeAllReference = (e: any) => {
    const newFilesList = [...filesList];

    newFilesList.forEach((listItem: any) => {
      listItem.tempReference = e.target.checked;
      listItem.tempFileType = calculateFileType(
        listItem.name,
        e.target.checked
      );
      listItem.previouslyMatchedTo = listItem.matchedTo;
    });

    // calculate matching
    newFilesList.forEach((listItem: any) => {
      if (listItem.previouslyMatchedTo === null) {
        listItem.matchedTo = calculateMatching(
          listItem.tempFileType,
          listItem.name,
          dtpFiles
        );
      } else {
        listItem.matchedTo = listItem.previouslyMatchedTo;
      }
    });

    setFilesList([...newFilesList]);
  };

  // on click of the reference checkbox
  const handleChangeReference = (e: any, record: any) => {
    // loop through filesList and find the item
    const file: any = filesList.find((item: any) => item.uid === record.uid);

    // update the reference based on checkbox
    record.tempReference = e.target.checked;
    file.tempReference = e.target.checked;

    // recalculate type
    file.tempFileType = calculateFileType(file.name, file.tempReference);
    file.previouslyMatchedTo = file.matchedTo;

    // set matched to - if was previously set don't recalculate
    // check if previouslyMatchedTo is empty
    if (file.previouslyMatchedTo === null) {
      file.matchedTo = calculateMatching(
        file.tempFileType,
        file.name,
        dtpFiles
      );
    } else {
      file.matchedTo = file.previouslyMatchedTo;
    }

    setFilesList([...filesList]);
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setUploading(false);
    setError({ hasError: false, errorMsg: "" });
    setFilesList([]);
    setVisible(false);
    setDtpFiles([]);
  };

  const handleCancel = () => {
    setLoadingAutoupload(false)
    hideModal();
  };

  // show errors in real time not only on processing upload
  useEffect(() => {
    const checkMatch = filesList.filter(
      (item: any) => !item.matchedTo && !item.tempReference
    );

    const checkXml = filesList.filter((item: any) => !item.xmlValid && item.tempFileType !=='REFERENCE');

    if (checkMatch.length > 0) {
      const list = checkMatch.map(({ name }: any) => name).join(", ");
      setError({
        hasError: true,
        errorMsg: (
          <div>
            <InfoCircleOutlined
              style={{
                color: "var(--red)",
                marginRight: "5px",
                userSelect: "none",
              }}
            />
            {`Please match/remove the following file${
              checkMatch.length > 1 ? "s" : ""
            }${
              props.projectStatus === "IN_PREPROCESSING"
                ? " or tick as reference"
                : ""
            }:`}
            <br />
            <p style={{ color: "rgba(0, 0, 0, 0.65)" }}>{list}</p>
          </div>
        ),
      });
    } else if (filesList.length < 1) {
      dtpFiles.length > 0 &&
        setError({
          hasError: true,
          errorMsg: `No files uploaded, please upload at least 1 file.`,
        });
    } else if (checkXml.length > 0) {
      const list = checkXml.map(({ name }: any) => name).join(", ");
      setError({
        hasError: true,
        errorMsg: `XML is invalid for ${list}. Unable to upload, please remove.`,
      });
    } else {
      setError({ hasError: false, errorMsg: "" });
    }
  }, [filesList, props.projectStatus, dtpFiles.length]);

  const messageResponse = (file: any) => {
    if (file.status !== "error") {
      return successfulNotification("Upload successful", file.name);
    } else if (file.response.response !== undefined) {
      return failedNotification(
        "Upload failed",
        file.response.response.data.error
      );
    } else {
      return failedNotification("Upload failed", file.response.message);
    }
  };

  const uploadMessages = () => {
    // if it's a reference file it will mentioned in the notification message
    filesList.forEach((file: any) => {
      if (file.status === "done") {
        successfulNotification(
          "Upload successful",
          <div>
            Name: {file.name}
            <br />
            File Type:{" "}
            <span style={{ color: "var(--tertiary-color)" }}>
              {file.tempFileType}
            </span>
            <br />
            Please Refresh. Uploaded files will be visible soon.
          </div>
        );
      }
    });
  };

  const handleUploadAdditionalXliff = async (additionalXliffs:any) => {

    for (let xliff of additionalXliffs) {
      try {

        let headers = {
          Authorization: `Bearer ${props.accessToken}`,
        };

        let data = {
          filename: xliff.originFileContent.name,
          fileType: xliff.fileType,
          matchedFileId: xliff._id ? xliff._id : "",
          reference: false
        };

        let response = await axios.post(
          `${props.baseURI}/projects/${props.shortId}/files/upload`,
          data,
          {
            headers: headers,
          }
        );

        let uploadURL = response.data.url;
        // PUT the file to S3
        await uploadAxiosInstance.put(uploadURL, xliff.originFileContent);

      } catch (error) {
        console.error(error)
        failedNotification('Failed to upload additional Xliff files.')
        setUploading(false);
      }
    }
  }

  const handleFileListUpload = async () => {
    let uploadErrors = false;

    // Loop through the files and upload each one
    for (let file of filesList) {
      try {
        //let file: any = file;
        //Set the status of the file to uploading
        file.status = "uploading";

        setFilesList([...filesList]);

        let headers = {
          Authorization: `Bearer ${props.accessToken}`,
        };

        let data = {
          filename: file.name,
          fileType: file.tempFileType,
          matchedFileId: file.matchedTo ? file.matchedTo._id : "", // fix for null value on encoding
          reference: file.tempReference,
        };

        // Get the URL to upload the file to
        let response = await axios.post(
          `${props.baseURI}/projects/${props.shortId}/files/upload`,
          data,
          {
            headers: headers,
          }
        );

        let uploadURL = response.data.url;
        // PUT the file to S3
        await uploadAxiosInstance.put(uploadURL, file.originFileContent);

        // Update the status of the file with success
        file.status = "done";
        setFilesList([...filesList]);

      } catch (error) {
        // Set upload errors to true
        uploadErrors = true;

        // File upload failed, set the status of the upload to error
        file.status = "error";
        file.response = error;

        setFilesList([...filesList]);
        setUploading(false);
      }
    }

    if (props.projectStatus === "IN_POSTPROCESSING" && autoUploading) {
      const additionalXliffs = filesList.filter((file:any) => file.matchedWithOtherXliff).map((file:any) => {
        return [...file.matchedWithOtherXliff.matchedXliffFiles.map((xliff:any) => {
          return {
            ...xliff,
            originFileContent: file.originFileContent,
          }
        })]
      }).flat();

      await handleUploadAdditionalXliff(additionalXliffs);
    }

    if (uploadErrors) {
      setError({
        hasError: true,
        errorMsg: "Failed to upload, please check and try again.",
      });

      // fix notification for successful and failed responses
      filesList.map((file: any) => messageResponse(file));

      // for activeProjectLink (dashboard) will display a redirect notification
      // if there is a successful uploaded file
      filesList.map(
        (file: any) =>
          file.status !== "error" &&
          props.activeProjectLink === true &&
          infoNotification("Check your documents", projectLink)
      );
    } else {
      hideModal();
      uploadMessages();
      // for activeProjectLink (dashboard) will display an redirect notification
      // will render a history link inside notification
      props.activeProjectLink === true &&
        infoNotification("Check your documents", projectLink);
    }
  };

  // called on click of upload button
  const handleUpload = async () => {

    let checkMatch = null;
    let checkXml = null;

    setUploading(true);

    checkXml = filesList.filter((item: any) => !item.xmlValid  && item.tempFileType !=='REFERENCE');

    // check if all files have been matched before uploading
    checkMatch = filesList.filter(
      (item: any) => !item.matchedTo && !item.tempReference
    );

    if (checkXml.length > 0) {
      setUploading(false);
      return;
    }

    // stop uploading
    if (checkMatch.length > 0) {
      setUploading(false);
      return;
    }

    // at least one file should be uploaded
    if (filesList.length < 1) {
      // stop uploading
      setUploading(false);
      return;
    }

    handleFileListUpload();
  };

  const mapWithSelectedKey = async (obj: any, props: any) => {
    if (props.projectStatus === "IN_POSTPROCESSING" && obj.matchedTo && obj.tempFileType === "TRANSLATED") {
    
      const matchedXliffFiles = await getMatchingXliffs(obj.matchedTo?._id);

      const newObj = {
        ...obj,
        matchedWithOtherXliff: {
          uploadedFilename: obj.name, 
          matchedXliffFiles: matchedXliffFiles.map((i:any)=> {return {...i, key: i.id}}),
          triedToMatchXliffs: true
        },
        selectedMatchToKey: obj?.matchedTo?._id,
        key: obj.wasZip ? obj.key : obj?.originFileContent.uid,
        uid: obj.wasZip ? obj.uid : obj?.originFileContent.uid, 
      };
      return newObj;
    } else {
      return {
        ...obj,
        matchedWithOtherXliff: null,
        selectedMatchToKey: obj?.matchedTo?._id,
      };
    }
  }
  
  function checkAndSetAutoUploadModal(list: any[], props: any, autoUploadModal: boolean, setAutoUploadModal: Function) {
    if (props.projectStatus === "IN_POSTPROCESSING") {
      const atLeastOneFileHasData = list.some(file => file.matchedTo && file.matchedWithOtherXliff?.matchedXliffFiles.length > 0);
      const allUploadedAreMatched = atLeastOneFileHasData && list.every(file => file.matchedTo && file.matchedWithOtherXliff?.triedToMatchXliffs);

      if (!autoUploadModal && allUploadedAreMatched) {
        setAutoUploadModal(true);
      } else {
        console.log('No matched xliff files.')
      }
    }
  }

  const draggerProps = {
    multiple: true,
    fileList: filesList,
    beforeUpload: async (file: any, fileList: any) => {
      // generate a new list with all files - normal / zip files
      let newFilesList = [];

      for (let singleFile of fileList) {
        let checkExt = getFileExtension(singleFile.name);

        if (checkExt === ".zip") {
          let zipList: any = [];
          zipList = await extractZip(singleFile);
          zipList.forEach((item: any) => {
            return newFilesList.push(item);
          });
        } else {
          // store the actual file inside originFileContent and pass all upload details
          const newFile = {
            ...singleFile,
            originFileContent: singleFile,
            name: singleFile.name,
            lastModified: singleFile.lastModified,
            lastModifiedDate: singleFile.lastModifiedDate,
            xmlValid:
              checkExt === ".xliff" || checkExt === ".xlf"
                ? await calculateXml(singleFile, singleFile.name)
                : true,
          };
          newFilesList.push(newFile);
        }
      }

      // loop through new files list and pass all data
      const updatedFileList = newFilesList.map((obj) => {
        return {
          ...obj,
          key: obj.uid,
          tempReference: obj.tempReference || false,
          tempFileType: calculateFileType(obj.name, obj.tempReference),
          matchedTo: null,
          previouslyMatchedTo: null,
          status: obj.status || "unknown",
        };
      });

      // calculate matching for each file
      let listWithMatching = updatedFileList.map((obj) => ({
        ...obj,
        matchedTo: calculateMatching(obj.tempFileType, obj.name, dtpFiles),
      }));

      // fix to auto tick as reference if the project status is IN PREPROCESSING
      // and fileType is TRANSLATED
      if (props.projectStatus === "IN_PREPROCESSING") {
        listWithMatching = listWithMatching.map((obj) => ({
          ...obj,
          tempReference:
            obj.tempFileType === "TRANSLATED" ? true : obj.tempReference,
        }));

        listWithMatching = listWithMatching.map((obj) => ({
          ...obj,
          tempFileType: calculateFileType(obj.name, obj.tempReference),
        }));
      }

      const listWithSelectedkeyPromises = listWithMatching.map(obj => mapWithSelectedKey(obj, props));
      Promise.all(listWithSelectedkeyPromises).then(listWithSelectedKey => {

        const list = [...filesList, ...listWithSelectedKey];

        checkAndSetAutoUploadModal(list, props, autoUploadModal, setAutoUploadModal);
      
        setFilesList(list);
      });

      // Turn off automatic uploading, this will be done when the user presses Upload
      return false;
    },
  };

  // Override Upload default upload
  const dummyRequest = () => {
    console.log("Prevent default upload");
  };

  const handleOkAutoModal = () => {
    setAutoUploading(true);
    setAutoUploadModal(false)
  }

  const handleCancelAutoModal = () => {
    setAutoUploading(false);
    setAutoUploadModal(false)
  }

  return (
    <>
      <Button
        type="primary"
        disabled={props.disabled || props.varStatus ==="INVOICE" && !canEditProjectInvoice}
        onClick={showModal}
        icon={<UploadOutlined />}
      >
        Upload
      </Button>
      <Modal
        title={props.title}
        open={visible}
        okText="Upload"
        onOk={handleUpload}
        okButtonProps={{ disabled: uploading }}
        cancelButtonProps={{ disabled: uploading }}
        confirmLoading={uploading}
        onCancel={handleCancel}
        width={1300}
        maskClosable={false}
      >
        <Dragger
          {...draggerProps}
          showUploadList={false}
          customRequest={dummyRequest}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Support for a single or bulk upload</p>
        </Dragger>
        {loadingDtpFiles ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0 -15px",
            }}
          >
            <Spin style={{ marginRight: "10px" }} /> Loading project details...
          </div>
        ) : (
          <Table
            columns={uploadColumns}
            dataSource={[...filesList]}
            size="small"
            pagination={false}
            className="upload-modal-table"
            locale={{ emptyText: "No files uploaded" }}
          />
        )}
        {error.hasError && (
          <Text
            type="danger"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px auto -15px",
              maxWidth: "60%",
            }}
          >
            {error.errorMsg}
          </Text>
        )}
        {loadingAutoupload && (
          <Text
            type="success"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px auto 10px auto",
              maxWidth: "60%",
            }}
          ><Spin size="small" style={{ marginRight: "10px" }} />
            Please wait, we are trying to match with additional xliffs
          </Text>
        )}
      </Modal>
      <AutoUploadModal 
        files={filesList.filter((file:any) => file.matchedWithOtherXliff)} 
        isOpen={autoUploadModal} 
        okHandle={handleOkAutoModal} 
        cancelHandle={handleCancelAutoModal}
      />
    </>
  );
};

export default UploadBtn;
