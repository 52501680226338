import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import axios from "axios";
import { DateTime } from "luxon";
import {
  Modal,
  Form,
  DatePicker,
  Space,
  Select,
  message,
  Tooltip,
  Input,
  Popconfirm,
  Button
} from "antd";
import { WarningOutlined } from "@ant-design/icons";
import postRequest from "../../api/postExtensionForm";
import { JustificationCodes } from "../../constants/placeholders";
import {
  ADD_BUTTON,
  CANCEL_BUTTON,
  MAKE_REQUEST,
  SELECT_DUE_DATE,
  SELECT_MESSAGE,
} from "../../constants/constants";
import { useMercuryContext } from "../../../../user-context";
import { usePermissions } from "../../../../hooks/usePermissions";

export const RequestExtensions = ({
  extensions,
  matchPLPLanguageCodes,
  clearLPRows,
  varStatus,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [justificationType, setJustificationType] = useState(null);
  const [justificationCode, setJustificationCode] = useState(0);
  const [requestedDueDate, setRequestedDueDate] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [caseId, setCaseId] = useState(null);
  const [justificationReasons, setJustificationReasons] = useState(null);
  const { TextArea } = Input;
  const { Option } = Select;
  const { accessToken, client } = useMercuryContext();
  const [popVisible, setPopVisible] = useState(false);
  const [form] = Form.useForm();
  const { hasPermissions } = usePermissions();

  const showModal = useCallback(async () => {
    setIsModalVisible(true);
    form.resetFields();
  }, [form]);

  const getJustifications = useCallback(async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/google-ges-v1/justification/paginate?page=1&limit=500`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res;
  }, [accessToken]);

  useEffect(() => {
    const apiCall = async () => {
      try {
        const res = await getJustifications();
        setJustificationReasons(res.data[0].documents);
      } catch (err) {
        console.error(err);
      }
    };
    apiCall();
  }, [getJustifications]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setLoading(false);
    clearLPRows();
    form.resetFields();
  },[clearLPRows, form])

  const postRejectHandler = (e) => {
    if (e) {
      e.forEach((currentValue) => {

        try {
          let extensionData = {
            projectId: currentValue.projectId,
            shortId: currentValue.shortId,
            sourceLanguageCode: currentValue.sourceLanguageCode,
            targetLanguageCode: currentValue.targetLanguageCode,
            originalDueDate: currentValue.originalDueDate,
            requestedDueDate: requestedDueDate,
            justificationCode: justificationCode,
            justification: justificationType.googleType,
            urgency: currentValue.urgency,
            requestedBy: currentValue.requestedBy,
            product: currentValue.product,
            productArea: currentValue.productArea,
            status: "Failed Validation",
            bulk: false,
            costCode: currentValue.costCode,
          };

          client.post(`/google-ges-v1/reject`, extensionData);

          clearLPRows();
          form.resetFields();

        } catch (error) {
          console.error(error);
        }
      });
    }
  }

  const rejectHandler = (extensions) => {
    // prevent duplication of modal
    Modal.confirm({
      title: 'Do you want to record the failed request?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        postRejectHandler(extensions);
      },
      onCancel: () => clearLPRows()
    });
  };

  const handleOk = () => {
    // Validations
    if (
      typeof justificationCode != "number" ||
      !justificationType ||
      !requestedDueDate
    ) {
      return message.error("Please validate all fields");
    }
    setLoading(true);

    // let submittedRequestData = {
    //   requestedDueDate: requestedDueDate,
    //   justificationType: justificationType,
    //   justificationCode: justificationCode,
    //   extensions:extensions,
    //   frequencyApi:frequencyApi,
    //   caseId:caseId,
    // }

    // Submitted Request data for debugging
    // console.log(submittedRequestData)

    postRequest(
      requestedDueDate,
      justificationType,
      justificationCode,
      extensions,
      setJustificationType,
      setJustificationCode,
      accessToken,
      setIsModalVisible,
      setLoading,
      setDisabled,
      caseId,
      setCaseId,
      rejectHandler,
    );
  };
  // for validation purposes
  const includesPilot = justificationType?.googleType.includes("Pilot");
  const includesL10n = justificationType?.googleType.includes("L10n");

  const handleSelection = (e) => {
    if (!e.$d) {
      setJustificationCode(e);
    } else {
      let dueDate = DateTime.fromISO(e.format('YYYY-MM-DDT13:59:59-00:00'))
      console.log('dueDate', dueDate.toISO())
      let dueDateToPST = dueDate.setZone("America/Los_Angeles")
      console.log('dueDateToPST', dueDateToPST.toISO())
      let dueDatePSTtimeSet = dueDateToPST.set({hour: 13, minutes: 59,  seconds: 59, milliseconds: 0 })
      console.log('dueDatePSTtimeSet', dueDatePSTtimeSet.toISO())
      let dueDateToUTC = dueDatePSTtimeSet.toUTC()
      console.log('dueDateToUTC', dueDateToUTC.toISO())
      let dueDateToISO = dueDateToUTC.toISO();
      console.log('dueDateToISO', dueDateToUTC.toISO())
      setRequestedDueDate(dueDateToISO);
    }
  };

  const handleJustification = (e) => {
    justificationReasons.filter((element) => {
      if (element.googleType === e) {
        return setJustificationType({
          kind: element.kind,
          limit: element.limit,
          googleType: element.googleType,
          frequency: element.frequency,
          description: element.description,
        });
      }
      return setJustificationType;
    });
  };

  const disabledDate = (current) => {
    // Cannot select days before today
    return current && current < moment().startOf("day");
  };

  const isUrgency = extensions?.filter(
    (extension) => extension.urgency !== "REGULAR"
  );

  const handleCaseId = (e) => setCaseId(e.target.value);

  const disableButton = () => (extensions?.length === 0 ? true : false);

  const caseIdPlaceholder = () => {
    if (includesL10n) return "Insert Case Number";
    if (includesPilot) return "Insert pilot/new product name";
    return null;
  };
  const caseIdLabel = () => {
    if (includesL10n) return "Case Id Number";
    if (includesPilot) return "Name of Pilot";
    return null;
  };

  const showExtPopconfirm = useCallback(() => {
    setPopVisible(true);
    matchPLPLanguageCodes();
  }, [matchPLPLanguageCodes])

  const handleExtPopOk = useCallback(() => {
    setPopVisible(false);
    clearLPRows();
  },[clearLPRows])

  const handleExtPopCancel = useCallback(() => {
    setPopVisible(false);
    showModal();
  }, [showModal])

  const requestFormValues = form.getFieldValue();

  console.log('requestFormValues', requestFormValues)

  const canEditProjectInvoice = hasPermissions([{ customerApplication: "Google", permissionName: "Invoice Project", action: "edit" }]);

  return (
    <>
      <Popconfirm
        open={popVisible}
        title="Has the project passed its launch date?"
        onConfirm={handleExtPopOk}
        onCancel={handleExtPopCancel}
        okText="Yes"
        cancelText="No"
        okType="danger"
        icon={<WarningOutlined style={{ color: "var(--red)" }} />}
        cancelButtonProps={{ type: "ghost" }}
        okButtonProps={{ type: "primary" }}
      >
        <Tooltip
          placement="top"
          color="#eb2c51"
          title={
            disableButton() && !isModalVisible
              ? "This project is not eligible for EXT"
              : null
          }
        >
          <Button
            type="primary"
            onClick={showExtPopconfirm}
            data-testid="button"
            disabled={disableButton() || varStatus ==="INVOICE" && !canEditProjectInvoice }
          >
            {MAKE_REQUEST}
          </Button>
        </Tooltip>
      </Popconfirm>

      <Modal
        forceRender
        destroyOnClose={true}
        title="Request Extension"
        maskClosable={false}
        open={isModalVisible}
        onCancel={() => handleCancel()}
        confirmLoading={loading}
        footer={null}
        data-testid="modal"
        getContainer={false}
      >
        <Form
          layout="vertical"
          style={{ width: "100%" }}
          autoComplete="off"
          initialValues={{ "array-validation": 0 }}
          form={form}
          name="extensionRequest"
        >
          <Form.Item
            name={["array-validation"]}
            label="Justification Code"
            rules={[
              {
                required: justificationCode === 0 ? false : true,
                message: SELECT_MESSAGE,
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select an option"
              data-testid="select"
              onChange={(e) => handleSelection(e)}
            >
              {JustificationCodes.map((option, index) => {
                return (
                  <Option value={option.value} key={index}>
                    {option.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Justification"
            name="validation"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select an option"
              data-testid="select"
              onSelect={(e) => handleJustification(e)}
            >
              {/* Remove the Urgent Daily Cap Exceeded Option from the Modal unless Urgency is 'Emergency' or 'URG48' */}
              {justificationReasons
                ?.sort((a, b) => a.googleType.localeCompare(b.googleType))
                .map((option, index) => {
                  if (
                    isUrgency.length === 0 &&
                    option.googleType === "Urgent Daily Cap Exceeded"
                  )
                    return null;
                  return (
                    <Option value={option.googleType} key={index}>
                      {option.googleType}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          {justificationType && form.getFieldValue('validation') !== undefined ? (
            <Form.Item>
              <TextArea
                name="justificationDescription"
                showCount
                value={justificationType.description}
                disabled={true}
                autoSize={true}
                style={{
                  height: "100%",
                }}
              />
            </Form.Item>
          ) : ('')}
          {(includesL10n || includesPilot) && (
            <Form.Item
              name="justification"
              label={caseIdLabel()}
              rules={[{ required: true }]}
            >
              <Input
                placeholder={caseIdPlaceholder()}
                allowClear
                onChange={(e) => handleCaseId(e)}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Requested Due"
            name="RequestedDue"
            rules={[{ required: true, message: SELECT_DUE_DATE }]}
          >
            <DatePicker
              showNow={false}
              disabledDate={disabledDate}
              onSelect={(e) => handleSelection(e)}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                data-testid="modal-button-cancel"
                type="ghost"
                htmlType="button"
                onClick={() => handleCancel()}
              >
                {CANCEL_BUTTON}
              </Button>
              <Button
                data-testid="modal-button-add"
                disabled={disabled}
                block={true}
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={() => handleOk()}
              >
                {ADD_BUTTON}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
